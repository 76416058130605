/* eslint-disable array-callback-return */
import React, { useState } from "react";
import Button from "@mui/material/Button";
import {
  Divider,
  IconButton,
  Stack,
  OutlinedInput,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Box,
  Chip,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector, useDispatch } from "react-redux";
import { EditStaffModel } from "../../../../Store/Reducers/staff_menu";
import StaffService from "../../../../Services/StaffService";
import DoneIcon from "@mui/icons-material/Done";
import { AddStaffList } from "../../../../Store/Reducers/staff_menu";
import { commonMenuProps } from "../../../../Utils/commonMethods";
import "./StaffModel.css";

// ==============================|| EDIT STAFF MODEL ||============================== //

const EditStaff = () => {
  const menu = useSelector((state) => state.staff_menu);
  const editStaffModel = menu.editStaffModel;
  const staffList = menu.staffList;
  const staffId = menu.staffId;
  const staffInfo = menu.staffInfo;
  const timePreference = menu.timePreference;

  const infrastructure_menu = useSelector((state) => state.infrastructure_menu);
  const departmentList = infrastructure_menu.departmentList;

  const dispatch = useDispatch();

  let modalOpen = false;
  editStaffModel === true ? (modalOpen = true) : (modalOpen = false);
  const [open, setOpen] = useState(modalOpen);

  const [staffCodeDetails, setStaffCodeDetails] = useState(staffInfo);
  const [staffNameDetails, setStaffNameDetails] = useState(staffInfo);
  const [staffDepartmentDetails, setStaffDepartmentDetails] =
    useState(staffInfo);
  const [editStaffCode, setEditStaffCode] = useState(staffList.staffCode);
  const [editStaffName, setEditStaffName] = useState(staffList.staffName);
  const [editedDepartment, setEditedDepartment] = useState(
    staffList.departmentId,
  );
  const [editIsDummyStaff, setEditIsDummyStaff] = useState(staffInfo.isDummy);
  const [handleEditStaffError, sethandleEditStaffError] = useState();
  const [editStaff, setEditStaff] = useState(false);

  const departmentOptions = [...departmentList];

  // Getting staff time preference
  let selectedTimePreferenceIds = [];
  timePreference
    .filter((val) => staffInfo.timePreferenceIds.includes(val.id))
    .map((res) => {
      selectedTimePreferenceIds.push(`${res.startTime} - ${res.endTime}`);
    });

  const [editedTimePreference, setEditedTimePreference] = useState(
    selectedTimePreferenceIds,
  );

  // Function used to close the model
  const handleClose = () => {
    setOpen(false);
    dispatch(EditStaffModel(false));
  };

  // Function used to handle staff code
  const handleEditStaffCode = (event) => {
    setEditStaffCode(event.target.value);
    setStaffCodeDetails(event.target.value);
  };

  // Function used to handle staff name
  const handleEditStaffName = (event) => {
    setEditStaffName(event.target.value);
    setStaffNameDetails(event.target.value);
  };

  // Function used to select the department
  const handleEditDepartment = (event) => {
    setEditedDepartment(event.target.value);
    setStaffDepartmentDetails(event.target.value);
  };

  // Function used to handle edit dummy staff
  const handleEditIsDummy = (event) => {
    setEditIsDummyStaff(event.target.value);
  };

  // Function used to select the time preference
  const handleEditTimePreference = (event) => {
    const {
      target: { value },
    } = event;
    setEditedTimePreference(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value,
    );
  };

  // Used to store selected time preference id
  let startTime, endTime;
  let selectedTimeId = [];

  editedTimePreference.forEach((timeString) => {
    if (typeof timeString === "string") {
      const selectedTime = timeString.split(" - ");
      if (selectedTime.length === 2) {
        startTime = selectedTime[0];
        endTime = selectedTime[1];
        timePreference
          .filter(
            (time) => time.startTime === startTime && time.endTime === endTime,
          )
          .forEach((res) => {
            selectedTimeId.push(res.id);
          });
      }
    }
  });

  // Function used to update the Staff List
  const changeStaff = (
    editStaffCode,
    editStaffName,
    editedDepartment,
    editedTimePreference,
  ) => {
    const updatedStaffData = staffList.map((obj) => {
      if (obj.id === staffId) {
        return {
          ...obj,
          staffCode:
            editStaffCode === undefined
              ? staffInfo.staffCode
              : editStaffCode.trim(),
          staffName:
            editStaffName === undefined
              ? staffInfo.staffName
              : editStaffName.trim(),
          departmentId:
            editedDepartment === undefined
              ? staffInfo.departmentId
              : editedDepartment,
          timePreferenceIds:
            selectedTimeId === undefined
              ? staffInfo.timePreferenceIds
              : selectedTimeId,
          isDummy:
            editIsDummyStaff === staffInfo.isDummy
              ? staffInfo.isDummy
              : JSON.parse(editIsDummyStaff.toLowerCase()),
        };
      }
      return obj;
    });
    dispatch(AddStaffList(updatedStaffData));
  };

  // Function used to Edit the staff
  const handleEdit = (event) => {
    sethandleEditStaffError("");
    let data = {
      staffCode:
        editStaffCode === undefined
          ? staffInfo.staffCode
          : editStaffCode.trim(),
      staffName:
        editStaffName === undefined
          ? staffInfo.staffName
          : editStaffName.trim(),
      departmentId:
        editedDepartment === undefined
          ? staffInfo.departmentId
          : editedDepartment,
      timePreferenceIds:
        selectedTimeId === undefined
          ? staffInfo.timePreferenceIds
          : selectedTimeId,
      isDummy:
        editIsDummyStaff === staffInfo.isDummy
          ? staffInfo.isDummy
          : JSON.parse(editIsDummyStaff.toLowerCase()),
    };
    StaffService.editStaff(staffId, data)
      .then((res) => {
        changeStaff(
          editStaffCode,
          editStaffName,
          editedDepartment,
          selectedTimeId,
        );
        handleClose();
      })
      .catch((error) => {
        sethandleEditStaffError(error.response.data);
      });
  };

  const ITEM_HEIGHT = 48;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5,
        width: 250,
      },
    },
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          sx: { width: "27rem", height: "37rem", boxShadow: "none" },
        }}
      >
        <Stack direction="row">
          <DialogTitle id="responsive-dialog-title">Edit Staff</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className="close-icon-staff"
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />

        <DialogContent>
          <DialogContentText>
            <center>
              <Typography className="model-staff-text add-text1">
                Staff Reference
              </Typography>
              <OutlinedInput
                id="staffCode"
                name="staffCode"
                type="text"
                value={staffCodeDetails.staffCode || editStaffCode}
                onChange={handleEditStaffCode}
                placeholder="Enter Staff Code"
                variant="outlined"
                size="small"
                className="input-style"
              />

              <br></br>
              <Typography className="model-staff-text add-text2">
                Staff Name
              </Typography>
              <OutlinedInput
                id="staffName"
                name="staffName"
                type="text"
                value={staffNameDetails.staffName || editStaffName}
                onChange={handleEditStaffName}
                placeholder="Enter Staff Name"
                variant="outlined"
                size="small"
                className="input-style"
              />

              <br></br>
              <Typography className="model-staff-text add-text3">
                Choose Department
              </Typography>
              <FormControl sx={{ m: 1 }}>
                <Select
                  value={
                    staffDepartmentDetails.departmentId || editedDepartment
                  }
                  onChange={handleEditDepartment}
                  size="small"
                  displayEmpty
                  className="department-dropdown"
                  MenuProps={commonMenuProps}
                >
                  <MenuItem value={0} disabled>
                    {" "}
                    Choose{" "}
                  </MenuItem>
                  {departmentOptions.map((option, id) => (
                    <MenuItem key={id} value={option.id}>
                      {option.departmentName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <br></br>

              <Typography className="model-staff-text add-text4">
                Choose Time Preference
              </Typography>
              <FormControl sx={{ m: 1, width: 300 }}>
                <Select
                  labelId="demo-multiple-chip-label"
                  id="timePreferenceIds"
                  className="staff-timePreference"
                  multiple
                  value={editedTimePreference}
                  onChange={handleEditTimePreference}
                  input={<OutlinedInput id="select-multiple-chip" />}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {timePreference.map((time) => (
                    <MenuItem
                      key={time.startTime + time.endTime}
                      value={`${time.startTime} - ${time.endTime}`}
                    >
                      {time.startTime} - {time.endTime}
                      {editedTimePreference.includes(
                        `${time.startTime} - ${time.endTime}`,
                      ) && <DoneIcon sx={{ marginLeft: "50%" }} />}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <br></br>

              <Typography sx={{ mt: 2 }} className="model-staff-text add-text5">
                Dummy Staff or not
              </Typography>
              <FormControl
                className="isDummy-radio-group-style isDummy-margin-edit"
                onChange={(e) => handleEditIsDummy(e)}
              >
                <RadioGroup
                  row
                  name="isDummyStaffOrNot"
                  aria-labelledby="isDummyStaffOrNot"
                  value={editIsDummyStaff}
                  sx={{ mt: 1, mb: 1 }}
                >
                  <FormControlLabel
                    name="isDummyStaffOrNot"
                    value={true}
                    control={<Radio />}
                    label="True"
                  />
                  <FormControlLabel
                    name="isDummyStaffOrNot"
                    value={false}
                    control={<Radio />}
                    label="False"
                  />
                </RadioGroup>
              </FormControl>

              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  className={
                    editStaffCode === "" ||
                    editStaffName === "" ||
                    editedDepartment === 0 ||
                    editedTimePreference === "" ||
                    editStaff === true
                      ? "save-building-button-disabled"
                      : "save-building-button"
                  }
                  disabled={
                    editStaffCode === "" ||
                    editStaffName === "" ||
                    editedDepartment === 0 ||
                    editedTimePreference === "" ||
                    editStaff === true
                  }
                  onClick={() => {
                    handleEdit();
                    setEditStaff(true);
                  }}
                >
                  Edit
                </Button>
                <Button
                  variant="outlined"
                  className="cancel-class-button"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Stack>
              {handleEditStaffError && (
                <>
                  <p className="duplicate-text">{handleEditStaffError}</p>
                  {handleEditStaffError !== "" &&
                    editStaff &&
                    setEditStaff(false)}
                </>
              )}
            </center>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EditStaff;
