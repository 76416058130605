import React, { useState } from "react";
import Button from "@mui/material/Button";
import {
  Divider,
  IconButton,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { DeleteFixedSlotGroupModel } from "../../../../Store/Reducers/fixedslot_menu";

// ==============================|| DELETE STAFF CONFIRMATION MODEL ||============================== //

const DeleteFixedSlotGroup = ({
  fixedSlotGroupId,
  handleRemoveFixedSlotGroup,
}) => {
  const menu = useSelector((state) => state.fixedslot_menu);
  const deleteFixedSlotGroupModel = menu.deleteFixedSlotGroupModel;
  const fixedSlotsGroup = menu.fixedSlotsGroup;

  let modalOpen = false;
  deleteFixedSlotGroupModel === true ? (modalOpen = true) : (modalOpen = false);
  const [open, setOpen] = useState(modalOpen);
  const [deleteFixed, setDeleteFixed] = useState(false);

  const dispatch = useDispatch();

  // Function used to close the model
  const handleClose = () => {
    setOpen(false);
    dispatch(DeleteFixedSlotGroupModel(false));
  };

  // Function used to delete a particular fixedslot from the list
  const handleDelete = () => {
    fixedSlotsGroup.map(
      (obj) =>
        fixedSlotGroupId === obj.id && handleRemoveFixedSlotGroup(obj.id),
    );
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          sx: { width: "20rem", height: "12rem", boxShadow: "none" },
        }}
      >
        <Stack direction="row">
          <DialogTitle id="responsive-dialog-title">
            {" Clear Confirmation"}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className="model-close-icon"
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />
        <DialogContent>
          <DialogContentText className="dialog-style" align="center">
            Are you sure you want to Delete ?
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action">
          <Button
            disabled={deleteFixed === true}
            className={deleteFixed === true ? "yes-btn-disabled" : "yes-btn"}
            onClick={() => {
              handleDelete();
              setDeleteFixed(true);
            }}
            variant="outlined"
          >
            Yes
          </Button>
          <Button className="no-btn" onClick={handleClose} variant="outlined">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteFixedSlotGroup;
