import React from "react";
import { useSelector } from "react-redux";
import { Modal, Grid } from "@mui/material";
import { uiTexts } from "../../../Utils/uiTexts";
import { StaffShortcutModal } from "./StaffShortcutModal";
import { RoomShortcutModal } from "./RoomShortcutModal";
import { ClassShortcutModal } from "./ClassShortcutModal";
import "../ShortcutModal/shortcutmodal.css";

// ------------------------------------------ Shortcut Modal ------------------------------------------ //

export const ShortcutModal = ({ ganttGroup }) => {
  const {
    labelText: { staffIdText, roomIdText, classIdText },
  } = uiTexts;

  const menu = useSelector((state) => state.menu);
  const { modalList } = menu;
  const isShortcutModalOpen = true;

  if (modalList.length > 0) {
    const latestModal = modalList[modalList.length - 1];
    const modalType = Object.keys(latestModal)[0];

    return (
      <Modal
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="shortcut-modal-overlay-background"
      >
        <Grid item xs={8.7} className="shortcut-modal">
          {modalType === staffIdText && (
            <StaffShortcutModal
              staffId={latestModal.staffId}
              isShortcutModalOpen={isShortcutModalOpen}
              ganttGroup={ganttGroup}
            />
          )}

          {modalType === roomIdText && (
            <RoomShortcutModal
              roomId={latestModal.roomId}
              isShortcutModalOpen={isShortcutModalOpen}
              ganttGroup={ganttGroup}
            />
          )}

          {modalType === classIdText && (
            <ClassShortcutModal
              classId={latestModal.classId}
              isShortcutModalOpen={isShortcutModalOpen}
              ganttGroup={ganttGroup}
            />
          )}
        </Grid>
      </Modal>
    );
  }
};
