import React, { useState } from "react";
import Button from "@mui/material/Button";
import {
  Divider,
  IconButton,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { DeleteRoomsModel } from "../../../../../Store/Reducers/infrastructure_menu";

// ==============================|| DELETE ROOM CONFIRMATION MODEL ||============================== //

const DeleteRoomConfirmation = ({ handleRemoveRoom }) => {
  const menu = useSelector((state) => state.infrastructure_menu);
  const deleteRoomsModel = menu.deleteRoomsModel;
  const roomId = menu.roomId;

  let modalOpen = false;
  deleteRoomsModel === true ? (modalOpen = true) : (modalOpen = false);
  const [open, setOpen] = useState(modalOpen);
  const [deleteRoom, setDeleteRoom] = useState(false);

  const dispatch = useDispatch();

  // Function used to close the model
  const handleClose = () => {
    setOpen(false);
    dispatch(DeleteRoomsModel(false));
  };

  // Function used to delete a particular room from the list
  const handleDelete = () => {
    handleRemoveRoom(roomId);
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          sx: { width: "20rem", height: "12rem", boxShadow: "none" },
        }}
      >
        <Stack direction="row">
          <DialogTitle id="responsive-dialog-title">
            {" Clear Confirmation"}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className="model-close-icon"
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />
        <DialogContent>
          <DialogContentText className="dialog-style" align="center">
            Are you sure you want to Delete ?
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action">
          <Button
            disabled={deleteRoom === true}
            className={deleteRoom === true ? "yes-btn-disabled" : "yes-btn"}
            onClick={() => {
              handleDelete();
              setDeleteRoom(true);
            }}
            variant="outlined"
          >
            Yes
          </Button>
          <Button className="no-btn" onClick={handleClose} variant="outlined">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteRoomConfirmation;
