/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import {
  Stack,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  Divider,
  DialogContent,
  DialogContentText,
  Select,
  MenuItem,
  FormControl,
  Grid,
  List,
  Checkbox,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import FixedSlotService from "../../../../Services/FixedSlotService";
import CloseIcon from "@mui/icons-material/Close";
import { EditFixedSlotGroupModel } from "../../../../Store/Reducers/fixedslot_menu";
import { FixedSlotsGroup } from "../../../../Store/Reducers/fixedslot_menu";
import "../FixedSlots.css";

// ==============================|| EDIT FIXED SLOT MODEL ||============================== //

const EditFixedSlotGroup = ({
  handleError,
  setPossibleRooms,
  setHandleErrorEdit,
  setSelectedCheckboxes,
  setEditSlot,
  setEditSelectedRooms,
  setEditSelectedStaffs,
  setDayId,
  setTimeId,
  setSelectRooms,
  setSelectedStaff,
  setRoomIdArr,
  setEditRoomIdArr,
  classId,
  classCourseOptions,
  getAllClassCourseName,
  combinedClassId,
  combinedClassOptions,
  getAllCombinedClassName,
  dayId,
  timeId,
  fixedSlotGroupId,
  fixedSlotInfo,
  selectAvailableRoomsEdit,
  selectAvailableStaffsEdit,
  handleEditFixedSlot,
  selectedCheckboxes,
  setFixedSlotInfo,
  setFixedSlot,
  fixedSlot,
  editSlot,
  emptyError,
  setEmptyError,
}) => {
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.fixedslot_menu);
  const editFixedSlotGroupModel = menu.editFixedSlotGroupModel;
  const fixedSlotsGroup = menu.fixedSlotsGroup;

  const course_menu = useSelector((state) => state.course_menu);
  const courseList = course_menu.courseList;

  const class_menu = useSelector((state) => state.class_menu);
  const classCourseAssignment = class_menu.classCourseAssignmentAll;

  const combined_class_menu = useSelector((state) => state.combined_class_menu);
  const classCombinedList = combined_class_menu.classCombinedList;

  const staff_menu = useSelector((state) => state.staff_menu);
  const staffList = staff_menu.staffList;
  const staffCourseAssignment = staff_menu.staffCourseAssignmentAll;

  let modalOpen = false;
  editFixedSlotGroupModel === true ? (modalOpen = true) : (modalOpen = false);
  const [open, setOpen] = useState(modalOpen);

  const [editFixed, setEditFixed] = useState(false);

  const [availableHoursEdit, setAvailableHoursEdit] = useState();
  const [availableStaffNameEdit, setAvailableStaffNameEdit] = useState([]);
  const [availableStaffHoursEdit, setAvailableStaffHoursEdit] = useState([]);
  const [classid, setClassid] = useState("");
  const [editIconClick, setEditIconClick] = useState(false);
  const fixedSlotGroupData = fixedSlotInfo;

  //Function to handle edit single slot in edit
  const handleEditSlotforEdit = (dayId, timeId) => {
    // Ensure dayId and timeId are numbers for comparison
    dayId = Number(dayId);
    timeId = Number(timeId);

    const slot = fixedSlotInfo.fixedSlotList.find(
      (slot) => slot.dayId === dayId && slot.timeId === timeId,
    );

    if (slot) {
      const selectedRooms = slot.staffAndRoomDetails.map(
        (detail) => detail.roomId,
      );
      const selectedStaffs = slot.staffAndRoomDetails.map(
        (detail) => detail.staffIds,
      );
      setEditSelectedStaffs(selectedStaffs);
      setEditSelectedRooms(selectedRooms);
      setEditSlot(slot);
    }
  };

  // Displaying Table for Edit
  const rows = 7;
  const cols = 6;
  const formatTime = (hour) => {
    let start, end;

    if (hour === 12) {
      start = "13:30";
      end = "14:30";
    } else if (hour === 13) {
      start = "14:30";
      end = "15:30";
    } else {
      start = `${hour}:30`;
      end = `${hour + 1}:30`;
    }

    return `${start} - ${end}`;
  };

  //Display Time table for Edit slot
  const timetableRowsEdit = [];

  for (let i = 0; i < rows; i++) {
    const cells = [];
    for (let j = 0; j < cols; j++) {
      if (i === 0 && j === 0) {
        cells.push(
          <th key={j} className="time-header">
            DAY/TIME
          </th>,
        );
      } else if (i === 0) {
        cells.push(
          <th key={j} className="time-header">
            {formatTime(8 + j)}
          </th>,
        );
      } else {
        if (j === 0) {
          cells.push(<th key={j} className="time-header">{`DAY ${i}`}</th>);
        } else {
          const cellName = `${i},${j}`;

          let isChecked = selectedCheckboxes.some(
            (checkbox) => checkbox.dayId === i && checkbox.timeId === j,
          );

          // Check if there's a corresponding fixedSlotInfo entry
          let shouldDisplayEditIcon = false;
          if (fixedSlotInfo && fixedSlotInfo.fixedSlotList) {
            fixedSlotInfo.fixedSlotList.forEach((slot) => {
              if (slot.dayId === i && slot.timeId === j) {
                isChecked = true; // Checkbox should be pre-checked
                shouldDisplayEditIcon = true; // Edit icon should be displayed
              }
            });
          }

          cells.push(
            <td key={j}>
              <Checkbox
                size="small"
                className={`custom-radio-style ${
                  shouldDisplayEditIcon === true ? "selected" : ""
                }`}
                name={cellName}
                checked={isChecked}
                onChange={(event) => {
                  handleChangeEditGroup(event, cellName);
                  setEditIconClick(true);
                }}
              />
              {shouldDisplayEditIcon && ( // Render the edit icon only when shouldDisplayEditIcon is true
                <Tooltip title="Edit Fixed Slot" placement="bottom" arrow>
                  <IconButton
                    className="edit-fixedslot-button"
                    type="submit"
                    variant="contained"
                    onClick={() => {
                      handleEditSlotforEdit(i, j);
                      setDayId(i);
                      setTimeId(j);
                      setEditIconClick(true);
                    }}
                  >
                    <EditIcon className="add-icon editicon-fixedslot" />
                  </IconButton>
                </Tooltip>
              )}
            </td>,
          );
        }
      }
    }
    timetableRowsEdit.push(<tr key={i}>{cells}</tr>);
  }

  const tableBodyEdit = timetableRowsEdit.map((row, index) => (
    <tr
      key={index}
      className={index % 2 === 0 ? "even-tableRow" : "odd-tableRow"}
    >
      {row.props.children}
    </tr>
  ));

  //Function to handle editGroup in edit
  const handleChangeEditGroup = (event, cellName) => {
    setSelectedCheckboxes([]);

    const [dayId, timeId] = cellName.split(",");

    const isUnsavedItem = fixedSlotInfo.fixedSlotList.some(
      (slot) =>
        slot.dayId === parseInt(dayId) && slot.timeId === parseInt(timeId),
    );

    if (event.target.checked) {
      setSelectedCheckboxes((prevCheckboxes) => [
        ...prevCheckboxes,
        { dayId: parseInt(dayId), timeId: parseInt(timeId) },
      ]);
    } else {
      if (!isUnsavedItem) {
        setSelectedCheckboxes((prevCheckboxes) =>
          prevCheckboxes.filter(
            (checkbox) =>
              !(
                checkbox.dayId === parseInt(dayId) &&
                checkbox.timeId === parseInt(timeId)
              ),
          ),
        );
      } else {
        const shouldDeleteFromGroup = window.confirm(
          "Are you sure you want to delete this fixedslot from fixedSlotsGroup?",
        );

        if (shouldDeleteFromGroup) {
          const updatedFixedSlotList = fixedSlotInfo.fixedSlotList.filter(
            (slot) => {
              return !(
                slot.dayId === parseInt(dayId) &&
                slot.timeId === parseInt(timeId)
              );
            },
          );
          const updatedFixedSlotInfo = { ...fixedSlotInfo };
          updatedFixedSlotInfo.fixedSlotList = updatedFixedSlotList;
          setFixedSlotInfo(updatedFixedSlotInfo);
          setFixedSlot(updatedFixedSlotList);
          setEditSlot(null);

          const updatedFixedSlotsGroup = fixedSlotsGroup.map((group) => {
            const updatedFixedSlotList = group.fixedSlotList.filter((slot) => {
              return !(
                slot.dayId === parseInt(dayId) &&
                slot.timeId === parseInt(timeId)
              );
            });

            return {
              ...group,
              fixedSlotList: updatedFixedSlotList,
            };
          });

          dispatch(FixedSlotsGroup(updatedFixedSlotsGroup));
        }
      }
    }
  };

  //Function to edit the fixedSlot group
  const handleEditFixedSlotGroup = () => {
    let data = {
      classCourseAssignmentId:
        fixedSlotInfo.classCourseAssignmentId === 0
          ? null
          : fixedSlotInfo.classCourseAssignmentId,
      combinedClassId:
        fixedSlotInfo.combinedClassId === 0
          ? null
          : fixedSlotInfo.combinedClassId,
      fixedSlotList: fixedSlot,
    };
    if (data.fixedSlotList.length !== 0) {
      FixedSlotService.editFixedSlotGroup(fixedSlotGroupId, data)
        .then((res) => {
          dispatch(FixedSlotsGroup(fixedSlotsGroup));

          if (res.status === 200) {
            setAvailableStaffNameEdit([]);
            setAvailableStaffHoursEdit([]);
          }

          dispatch(EditFixedSlotGroupModel(false));

          setAvailableStaffNameEdit([]);
          setAvailableStaffHoursEdit([]);
          setEditSlot(null);
          setEditSelectedRooms([]);
          setEditSelectedStaffs([]);
          setDayId("");
          setTimeId("");
          setSelectRooms([]);
          setSelectedStaff([]);
          setRoomIdArr([]);
          setEditRoomIdArr([]);
          setHandleErrorEdit();
          setEmptyError();
          setSelectedCheckboxes([]);
          setPossibleRooms([]);
          setFixedSlotInfo([]);
          setFixedSlot([]);
        })

        .catch((error) => {
          setHandleErrorEdit(error.response.data);
        });
    } else {
      handleClose();
    }
  };

  // Function used to close the model
  const handleClose = () => {
    setOpen(false);
    dispatch(EditFixedSlotGroupModel(false));

    setAvailableStaffNameEdit([]);
    setAvailableStaffHoursEdit([]);
    setEditSlot(null);
    setEditSelectedRooms([]);
    setEditSelectedStaffs([]);
    setDayId("");
    setTimeId("");
    setSelectRooms([]);
    setSelectedStaff([]);
    setEditRoomIdArr([]);
    setHandleErrorEdit();
    setEmptyError();
    setSelectedCheckboxes([]);
    setPossibleRooms([]);
    setFixedSlotInfo([]);
    setFixedSlot([]);
    setEditIconClick(false);
  };

  //Function to retrieve available hours and available staff for a course
  const getAvailableHours = () => {
    if (fixedSlotInfo.classCourseAssignmentId !== null) {
      classCourseAssignment
        .filter(
          (clsCourse) => clsCourse.id === fixedSlotInfo.classCourseAssignmentId,
        )
        .map((resClassCourse) => {
          courseList
            .filter((course) => course.id === resClassCourse.courseId)
            .map((res) => {
              setAvailableHoursEdit(res.courseHours);
            });
        });
      const matchingStaffCourses = staffCourseAssignment.filter(
        (staffCourse) =>
          staffCourse.classCourseAssignmentId ===
          fixedSlotInfo.classCourseAssignmentId,
      );

      if (matchingStaffCourses.length > 0) {
        const matchingStaffIds = matchingStaffCourses
          .sort((a, b) => a.staffId - b.staffId)
          .map((staffCourse) => staffCourse.staffId);
        let namesArray = [];
        for (let i = 0; i < matchingStaffIds.length; i++) {
          let matchingStaffNames = staffList
            .filter((staff) => staff.id === matchingStaffIds[i])
            .map((staff) => staff.staffName);
          namesArray.push(matchingStaffNames);
        }
        let names = namesArray.flat();
        let hoursArray = [];
        for (let i = 0; i < matchingStaffIds.length; i++) {
          let matchingHours = matchingStaffCourses
            .filter((item) => item.staffId === matchingStaffIds[i])
            .map((obj) => obj.teachingHours);
          hoursArray.push(matchingHours);
        }
        let hours = hoursArray.flat();

        setAvailableStaffHoursEdit(hours);
        if (names.length > 0) {
          setAvailableStaffNameEdit(names);
        }
      }
    }
    if (fixedSlotInfo.combinedClassId !== null) {
      classCombinedList
        .filter((comcls) => comcls.id === fixedSlotInfo.combinedClassId)
        .map((resCom) => {
          courseList
            .filter((course) => course.id === resCom.courseId)
            .map((res) => {
              setAvailableHoursEdit(res.courseHours);
            });
        });
      const matchingStaffCourses = staffCourseAssignment.filter(
        (staffCourse) =>
          staffCourse.combinedClassId === fixedSlotInfo.combinedClassId,
      );

      if (matchingStaffCourses.length > 0) {
        const matchingStaffIds = matchingStaffCourses
          .sort((a, b) => a.staffId - b.staffId)
          .map((staffCourse) => staffCourse.staffId);
        let namesArray = [];
        for (let i = 0; i < matchingStaffIds.length; i++) {
          let matchingStaffNames = staffList
            .filter((staff) => staff.id === matchingStaffIds[i])
            .map((staff) => staff.staffName);
          namesArray.push(matchingStaffNames);
        }
        let names = namesArray.flat();
        let hoursArray = [];
        for (let i = 0; i < matchingStaffIds.length; i++) {
          let matchingHours = matchingStaffCourses
            .filter((item) => item.staffId === matchingStaffIds[i])
            .map((obj) => obj.teachingHours);
          hoursArray.push(matchingHours);
        }
        let hours = hoursArray.flat();

        setAvailableStaffHoursEdit(hours);
        if (names.length > 0) {
          setAvailableStaffNameEdit(names);
        }
      }
    }

    return 0;
  };

  //Function to find tha assigned hours for staff
  const getAssignedStaffHoursEdit = (name) => {
    const staffIds = staffList
      .filter((staff) => staff.staffName === name)
      .map((res) => res.id);

    return getStaffIdOccurrenceEdit(staffIds, fixedSlotInfo);
  };

  const getStaffIdOccurrenceEdit = (staffIds, slotData) => {
    let occurrenceCount = 0;

    if (Array.isArray(slotData.fixedSlotList)) {
      slotData.fixedSlotList.forEach((slot) => {
        if (Array.isArray(slot.staffAndRoomDetails)) {
          slot.staffAndRoomDetails.forEach((detail) => {
            if (Array.isArray(detail.staffIds)) {
              occurrenceCount += staffIds.filter((id) =>
                detail.staffIds.includes(id),
              ).length;
            }
          });
        }
      });
    }

    return occurrenceCount;
  };

  useEffect(() => {
    fixedSlotsGroup
      .filter((slot) => slot.id === fixedSlotGroupId)
      .map((res) => {
        if (res.classCourseAssignmentId !== null) {
          setClassid(1);
        } else if (res.combinedClassId !== null) {
          setClassid(2);
        }
      });
    getAvailableHours();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div>
        <Dialog
          open={open}
          aria-labelledby="responsive-dialog-title"
          PaperProps={{
            sx: {
              maxWidth: "70rem",
              width: "70%",
              height: "100%",
              minHeight: "45rem",
            },
          }}
        >
          <Stack direction="row">
            <DialogTitle id="responsive-dialog-title">
              <Grid sx={{ ml: 0.5 }}>
                <b>Edit Fixed Slot</b>
              </Grid>
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              className="close-icon-fixedslots"
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Divider />

          <DialogContent>
            <DialogContentText>
              <center>
                <Grid container direction="row" className="fixedslots-area">
                  <Grid item>
                    <Grid sx={{ ml: 5 }}>
                      <Grid sx={{ ml: -15, mb: 1.5, mt: 0 }}>
                        <Typography className="fixedslot-title-text">
                          <b>Edit Fixed Slot</b>
                        </Typography>
                      </Grid>

                      <Grid sx={{ ml: 0 }}>
                        <Grid sx={{ ml: -23, mb: 1 }}>
                          <Typography className="model-text fixedslot-text">
                            Selected Class
                          </Typography>
                        </Grid>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          className="class-dropdown"
                          value={classid}
                          disabled
                        >
                          <MenuItem value={0} disabled>
                            Choose
                          </MenuItem>
                          <MenuItem value={1}>Class Course </MenuItem>
                          <MenuItem value={2}>Combined Class Course</MenuItem>
                        </Select>
                      </Grid>

                      <br></br>

                      <Grid sx={{ ml: 0 }}>
                        {classid === 1 ? (
                          <>
                            <Grid sx={{ ml: -21.7 }}>
                              <Typography className="model-text fixedslot-text">
                                Selected Course
                              </Typography>
                            </Grid>
                            <FormControl sx={{ m: 1 }}>
                              <Select
                                value={fixedSlotInfo.classCourseAssignmentId}
                                size="small"
                                displayEmpty
                                className="department-dropdown"
                                disabled
                              >
                                {classCourseOptions.map(
                                  (combinedName, index) => (
                                    <MenuItem
                                      key={index}
                                      value={combinedName.id}
                                    >
                                      {getAllClassCourseName(
                                        combinedName.classId,
                                        combinedName.courseId,
                                      )}
                                    </MenuItem>
                                  ),
                                )}
                              </Select>
                            </FormControl>
                          </>
                        ) : (
                          <>
                            <Grid sx={{ ml: -24 }}>
                              <Typography className="model-text fixedslot-text">
                                Selected Course
                              </Typography>
                            </Grid>

                            <FormControl sx={{ m: 1 }}>
                              <Select
                                value={fixedSlotGroupData.combinedClassId}
                                size="small"
                                displayEmpty
                                className="department-dropdown"
                                disabled
                              >
                                {combinedClassOptions.map(
                                  (combinedName, index) => (
                                    <MenuItem
                                      key={index}
                                      value={combinedName.id}
                                    >
                                      {getAllCombinedClassName(
                                        combinedName.courseId,
                                        combinedName.id,
                                      )}
                                    </MenuItem>
                                  ),
                                )}
                              </Select>
                            </FormControl>
                          </>
                        )}
                      </Grid>

                      <Grid sx={{ ml: -10, mt: 2.5, mb: 1.5 }}>
                        <Typography className="fixedslot-title-text">
                          <b>Edit Specification</b>
                        </Typography>
                      </Grid>

                      <Grid>
                        <Grid sx={{ ml: -22, mt: 2.5, mb: 1.5 }}>
                          <Typography className="model-staff-text fixedslot-text">
                            Available Hours
                          </Typography>
                        </Grid>

                        <Grid sx={{ ml: 37 }}>
                          <Typography className="model-staff-text fixedslot-text1 fixedslot-text-bg1">
                            {availableHoursEdit}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid>
                        <Grid sx={{ ml: -23, mt: 2.5, mb: 1 }}>
                          <Typography className="model-staff-text fixedslot-text">
                            Available Staff
                          </Typography>
                        </Grid>
                        <Grid>
                          <List
                            className="fixedslot-stafflist"
                            subheader={<li />}
                          >
                            <Grid container mt={1}>
                              <Grid item xs={4} sx={{ ml: -2.7 }}>
                                <Typography className="model-staff-text fixedslot-text3">
                                  Staff Name
                                </Typography>
                              </Grid>
                              <Grid item xs={4} sx={{ ml: 1.8 }}>
                                <Typography className="model-staff-text fixedslot-text3">
                                  Available Hours
                                </Typography>
                              </Grid>
                              <Grid item xs={4} sx={{ ml: -3 }}>
                                <Typography className="model-staff-text fixedslot-text3">
                                  Assigned Hours
                                </Typography>
                              </Grid>
                            </Grid>
                            <Divider className="fixedslot-divider" />
                            {availableStaffNameEdit.map((name, index) => (
                              <Grid container mt={1} key={index}>
                                <Grid item xs={4} sx={{ ml: 2 }}>
                                  <Typography className="model-slot-text">
                                    {name}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  <Typography className="model-slot-text">
                                    {availableStaffHoursEdit[index]}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4} sx={{ ml: -3 }}>
                                  <Typography className="model-slot-text">
                                    {getAssignedStaffHoursEdit(name)}
                                  </Typography>
                                </Grid>
                              </Grid>
                            ))}
                          </List>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid sx={{ ml: 25, mt: -1.5, mb: 1.5 }}>
                  <Typography className="fixedslot-title-text">
                    <b>Slot Selection</b>
                  </Typography>
                </Grid>
                <Grid sx={{ ml: 31, mt: -0.1, mb: 1.5 }}>
                  <Typography className="model-staff-text fixedslot-text3">
                    Edit available hours in the table
                  </Typography>
                </Grid>

                <Grid sx={{ ml: 49 }}>
                  <table className="table-body">
                    <tbody>{tableBodyEdit}</tbody>
                  </table>
                </Grid>

                <Grid>
                  <Grid sx={{ ml: 66.4, mt: 2.5, mb: 1.5 }}>
                    <Stack direction="row">
                      <Typography className="model-staff-text fixedslot-text">
                        Edit Rooms
                      </Typography>
                      {dayId && timeId && (
                        <Grid className="day-time" sx={{ mt: -0.5, ml: 1 }}>
                          <div>
                            <span className="noteStyle">NOTE&nbsp;&nbsp;</span>
                            <span className="labelStyle">Day Id:&nbsp;</span>
                            <span className="valueStyle">{dayId}</span>
                            <span className="labelStyle">, Time Id:&nbsp;</span>
                            <span className="valueStyle">{timeId}</span>
                          </div>
                        </Grid>
                      )}
                    </Stack>
                  </Grid>

                  <Grid sx={{ ml: 65.5 }}>
                    <div className="available-display">
                      {selectAvailableRoomsEdit}
                    </div>
                  </Grid>
                </Grid>

                <Grid>
                  <Grid sx={{ ml: 14, mt: 1.5, mb: 1.5 }}>
                    <Typography className="model-staff-text fixedslot-text">
                      Edit Staff
                    </Typography>
                  </Grid>

                  <Grid sx={{ ml: 65.5 }}>
                    <div className="available-display">
                      {selectAvailableStaffsEdit}
                    </div>
                  </Grid>
                </Grid>

                <Stack direction="row" spacing={5} sx={{ ml: 66.5, mt: 2.5 }}>
                  <Button
                    variant="contained"
                    className={
                      editFixed === true ||
                      emptyError ||
                      (editIconClick === false &&
                        availableHoursEdit ===
                          fixedSlotInfo?.fixedSlotList?.length)
                        ? "save-slot-fixedslot-disabled"
                        : "save-slot-fixedslot"
                    }
                    onClick={() => {
                      handleEditFixedSlot(dayId, timeId);
                      availableHoursEdit ===
                        fixedSlotInfo?.fixedSlotList.length &&
                        setEditIconClick(false);
                    }}
                    disabled={
                      editFixed === true ||
                      emptyError ||
                      (editIconClick === false &&
                        availableHoursEdit ===
                          fixedSlotInfo?.fixedSlotList?.length)
                    }
                  >
                    Edit Slot
                  </Button>

                  <Grid>
                    <Button
                      variant="contained"
                      className={
                        editFixed === true ||
                        availableHoursEdit !==
                          fixedSlotInfo?.fixedSlotList.length
                          ? "save-slot-fixedslot-disabled"
                          : "save-slot-fixedslot"
                      }
                      onClick={() => {
                        setEditFixed(true);
                        handleEditFixedSlotGroup(dayId, timeId);
                      }}
                      disabled={
                        availableHoursEdit !==
                        fixedSlotInfo.fixedSlotList.length
                      }
                    >
                      Confirm
                    </Button>
                  </Grid>
                </Stack>
                {handleError && (
                  <p className="duplicate-text handle-fixed-error">
                    {handleError}
                  </p>
                )}
                {emptyError && (
                  <p className="duplicate-text handle-fixed-error">
                    {emptyError}
                  </p>
                )}
              </center>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default EditFixedSlotGroup;
