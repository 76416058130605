import React, { useState } from "react";
import Button from "@mui/material/Button";
import {
  Divider,
  IconButton,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { DeleteStaffCourseModel } from "../../../../Store/Reducers/staff_menu";

// ==============================|| DELETE STAFF COURSE ASSIGNMENT CONFIRMATION MODEL ||============================== //

const DeleteStaffCourseAssignment = ({ handleRemoveStaffCourse }) => {
  const menu = useSelector((state) => state.staff_menu);
  const deleteStaffCourseModel = menu.deleteStaffCourseModel;
  const staffCourseAssignment = menu.staffCourseAssignment;
  const staffCourseId = menu.staffCourseId;

  let modalOpen = false;
  deleteStaffCourseModel === true ? (modalOpen = true) : (modalOpen = false);
  const [open, setOpen] = useState(modalOpen);
  const [deleteStaffCourse, setDeleteStaffCourse] = useState(false);

  const dispatch = useDispatch();

  // Function used to close the model
  const handleClose = () => {
    setOpen(false);
    dispatch(DeleteStaffCourseModel(false));
  };

  // Function used to delete a particular staff course assignment from the list
  const handleDelete = () => {
    staffCourseAssignment.map(
      (obj) => staffCourseId === obj.id && handleRemoveStaffCourse(obj.id),
    );
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          sx: { width: "20rem", height: "12rem", boxShadow: "none" },
        }}
      >
        <Stack direction="row">
          <DialogTitle id="responsive-dialog-title">
            {" Clear Confirmation"}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className="model-close-icon"
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />
        <DialogContent>
          <DialogContentText className="dialog-style" align="center">
            Are you sure you want to Delete ?
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action">
          <Button
            disabled={deleteStaffCourse === true}
            className={
              deleteStaffCourse === true ? "yes-btn-disabled" : "yes-btn"
            }
            onClick={() => {
              handleDelete();
              setDeleteStaffCourse(true);
            }}
            variant="outlined"
          >
            Yes
          </Button>
          <Button className="no-btn" onClick={handleClose} variant="outlined">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteStaffCourseAssignment;
