/* eslint-disable array-callback-return */
import React, { useState } from "react";
import Tab from "@mui/material/Tab";
import {
  Tabs,
  Typography,
  Box,
  OutlinedInput,
  InputAdornment,
  Stack,
  Grid,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector } from "react-redux";
import moment from "moment";
import WarningsClearConfirmation from "./ClearConfirmation/WarningsClearConfirmation";
import ClearResultConfirmation from "./ClearConfirmation/ClearResultConfirmation";
import ViewCountModel from "./Warnings/ViewCountModel";
import DownloadButton from "./DownloadButton";
import TimetableClassAccordion from "./TimetableAccordions/TimetableClassAccordion";
import TimetableStaffAccordion from "./TimetableAccordions/TimetableStaffAccordion";
import TimetableRoomAccordion from "./TimetableAccordions/TimetableRoomAccordion";

// ==============================|| TIMETABLE TABS ||============================== //

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// ==============================|| MAIN FUNCTION ||============================== //

export default function TimetableTabs({
  runalgorithm,
  clearResult,
  algorithmStatusCode,
}) {
  const menu = useSelector((state) => state.menu);
  const handleFailedModel = menu.handleFailedModel;
  const clearResultConfirm = menu.clearResultConfirm;
  const viewOverallCount = menu.viewOverallCount;
  const timeList = menu.timeList;
  const resultClassList = menu.resultClassList;
  const resultStaffList = menu.resultStaffList;
  const resultRoomList = menu.resultRoomList;

  const building_menu = useSelector((state) => state.infrastructure_menu);
  const buildingList = building_menu.buildingList;
  const departmentList = building_menu.departmentList;

  const course_menu = useSelector((state) => state.course_menu);
  const courseList = course_menu.courseList;

  const class_menu = useSelector((state) => state.class_menu);
  const classCourseAssignment = class_menu.classCourseAssignmentAll;

  const day_menu = useSelector((state) => state.menu);
  const dayList = day_menu.dayList;

  const [value, setValue] = useState(0);
  const [searchDepartmentList, setSearchDepartmentList] =
    useState(departmentList);
  const [searchBuildingList, setSearchBuildingList] = useState(buildingList);

  const [ganttGroup, setGanttGroup] = useState([]);
  const [ganttClassItems, setGanttClassItems] = useState([]);
  const [ganttStaffItems, setGanttStaffItems] = useState([]);
  const [ganttRoomItems, setGanttRoomItems] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [expandedAccordion, setExpandedAccordion] = useState([]);
  const [expandedMainAccordion, setExpandedMainAccordion] = useState([]);
  const anchorRef = React.useRef(null);
  const [selectedDepartment, setSelectedDepartment] = useState(0);

  const departmentOptions = [...departmentList];

  // Function used to select the department
  const handleSelectDepartment = (event) => {
    setSelectedDepartment(event.target.value);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setSearchValue("");
    setSearchDepartmentList(departmentList);
    setSearchBuildingList(buildingList);
    setExpandedAccordion([]);
    setExpandedMainAccordion([]);
  };

  // Function used to retrieve search text
  const handleSearchChange = (e) => {
    e.preventDefault();
    setSearchValue(e.target.value);
    searchFixedSlot(e.target.value);
  };

  // Function used for search course
  const searchFixedSlot = (text) => {
    const filteredDepartment = departmentList.filter((value) => {
      const searchText = text.toLowerCase();
      const lowercaseClassName = value.departmentName.toLowerCase();
      const uppercaseClassName = value.departmentName.toUpperCase();
      return (
        lowercaseClassName.includes(searchText) ||
        uppercaseClassName.includes(searchText)
      );
    });
    setSearchDepartmentList(filteredDepartment);

    const filteredBuilding = buildingList.filter((value) => {
      const searchText = text.toLowerCase();
      const lowercaseClassName = value.buildingName.toLowerCase();
      const uppercaseClassName = value.buildingName.toUpperCase();
      return (
        lowercaseClassName.includes(searchText) ||
        uppercaseClassName.includes(searchText)
      );
    });
    setSearchBuildingList(filteredBuilding);
  };

  const handleAccordion = (id) => (event, isExpanded) => {
    // Check if the accordion is being expanded or collapsed
    if (isExpanded) {
      // If the accordion ID is not already in the array, add it
      if (!expandedMainAccordion.includes(id)) {
        setExpandedMainAccordion([...expandedMainAccordion, id]);
      }
    } else {
      // If the accordion ID is in the array, remove it
      if (expandedMainAccordion.includes(id)) {
        setExpandedMainAccordion(
          expandedMainAccordion.filter((accordionId) => accordionId !== id),
        );
      }
    }
  };

  const handleClassAccordion = (id) => (event, isExpanded) => {
    setGanttGroup([]);

    // Check if the accordion is being expanded or collapsed
    if (isExpanded) {
      // If the accordion ID is not already in the array, add it
      if (!expandedAccordion.includes(id)) {
        setExpandedAccordion([...expandedAccordion, id]);
      }
    } else {
      // If the accordion ID is in the array, remove it
      if (expandedAccordion.includes(id)) {
        setExpandedAccordion(
          expandedAccordion.filter((accordionId) => accordionId !== id),
        );
      }
    }

    let index = 1;
    let tempGanttItems = []; // Create a temporary array to collect items

    if (value === 0 && isExpanded === true) {
      resultClassList
        .filter((resultData) => resultData.classId === id)
        .map((result) => {
          let courseName = "",
            courseCode = "",
            courseType = "";

          result.classScheduleList.forEach((resClassSchedule) => {
            let classScheduleLength =
              resClassSchedule.classSubjectsScheduleList.length;

            for (let i = 0; i < classScheduleLength; i++) {
              const classScheduleDetail =
                resClassSchedule.classSubjectsScheduleList[i];

              const assignedCourse = classCourseAssignment.find(
                (clsCourse) =>
                  clsCourse.id === classScheduleDetail.classCourseAssignmentId,
              );

              if (assignedCourse) {
                const resCourse = courseList.find(
                  (course) => course.id === assignedCourse.courseId,
                );

                if (resCourse) {
                  courseName = resCourse.courseName;
                  courseCode = resCourse.courseCode;
                  courseType = resCourse.courseTypeName;
                }
              }

              timeList
                .filter((timeData) => timeData.id === resClassSchedule.timeId)
                // eslint-disable-next-line no-loop-func
                .map((resTime) => {
                  // Convert time format
                  const startTime = resTime.startTime
                    .replace("01:30", "13:30")
                    .replace("02:30", "14:30")
                    .replace("03:30", "15:30");
                  const endTime = resTime.endTime
                    .replace("02:30", "14:30")
                    .replace("03:30", "15:30");

                  const stime = moment().startOf("day").add(startTime, "hour");
                  const etime = moment().startOf("day").add(endTime, "hour");

                  tempGanttItems.push({
                    id: index,
                    group: resClassSchedule.dayId,
                    title: courseName,
                    start_time: stime.valueOf(),
                    end_time: etime.valueOf(),
                    courseCode: courseCode,
                    courseType: courseType,
                    classCourseAssignmentId:
                      classScheduleDetail.classCourseAssignmentId,
                    combinedClassId: classScheduleDetail.combinedClassId,
                    roomId: classScheduleDetail.roomId,
                    staffId: classScheduleDetail.staffIds,
                    sTime: startTime,
                  });
                  index++;
                });
            }
          });

          if (isExpanded === true) {
            if (tempGanttItems.length === 0) {
              setGanttClassItems([-1]); // Set ganttItems to an array containing -1
            } else {
              const existingItemIndex = ganttClassItems.findIndex(
                (item) => item.id === id,
              );

              if (existingItemIndex === -1) {
                // If not exists, add a new item
                setGanttClassItems((prevGanttClassItems) => [
                  ...prevGanttClassItems,
                  {
                    id: id,
                    timeLineItem: tempGanttItems,
                  },
                ]);
              }
            }
          }
        });
    }
    if (value === 1 && isExpanded === true) {
      resultStaffList
        .filter((resultData) => resultData.staffId === id)
        .map((result) => {
          let courseName = "",
            courseCode = "",
            courseType = "";

          result.staffScheduleList.forEach((resStaffSchedule) => {
            let staffScheduleLength =
              resStaffSchedule.staffSubjectsScheduleList.length;

            for (let i = 0; i < staffScheduleLength; i++) {
              const staffScheduleDetail =
                resStaffSchedule.staffSubjectsScheduleList[i];

              const assignedCourse = classCourseAssignment.find(
                (clsCourse) =>
                  clsCourse.id === staffScheduleDetail.classCourseAssignmentId,
              );

              if (assignedCourse) {
                const resCourse = courseList.find(
                  (course) => course.id === assignedCourse.courseId,
                );

                if (resCourse) {
                  courseName = resCourse.courseName;
                  courseCode = resCourse.courseCode;
                  courseType = resCourse.courseTypeName;
                }
              }

              timeList
                .filter((timeData) => timeData.id === resStaffSchedule.timeId)
                // eslint-disable-next-line no-loop-func
                .map((resTime) => {
                  // Convert time format
                  const startTime = resTime.startTime
                    .replace("01:30", "13:30")
                    .replace("02:30", "14:30")
                    .replace("03:30", "15:30");
                  const endTime = resTime.endTime
                    .replace("02:30", "14:30")
                    .replace("03:30", "15:30");

                  const stime = moment().startOf("day").add(startTime, "hour");
                  const etime = moment().startOf("day").add(endTime, "hour");

                  tempGanttItems.push({
                    id: index,
                    group: resStaffSchedule.dayId,
                    title: courseName,
                    start_time: stime.valueOf(),
                    end_time: etime.valueOf(),
                    courseCode: courseCode,
                    courseType: courseType,
                    classCourseAssignmentId:
                      staffScheduleDetail.classCourseAssignmentId,
                    combinedClassId: staffScheduleDetail.combinedClassId,
                    roomId: staffScheduleDetail.roomId,
                    staffId: result.staffId,
                    sTime: startTime,
                  });
                  index++;
                });
            }
          });
          if (isExpanded === true) {
            if (tempGanttItems.length === 0) {
              setGanttStaffItems([-1]); // Set ganttItems to an array containing -1
            } else {
              const existingItemIndex = ganttStaffItems.findIndex(
                (item) => item.id === id,
              );

              if (existingItemIndex === -1) {
                // If not exists, add a new item
                setGanttStaffItems((prevGanttStaffItems) => [
                  ...prevGanttStaffItems,
                  {
                    id: id,
                    timeLineItem: tempGanttItems,
                  },
                ]);
              }
            }
          }
        });
    }
    if (value === 2 && isExpanded === true) {
      resultRoomList
        .filter((resultData) => resultData.roomId === id)
        .map((result) => {
          let courseName = "",
            courseCode = "",
            courseType = "";

          result.roomScheduleList.forEach((resRoomSchedule) => {
            let roomScheduleLength =
              resRoomSchedule.roomSubjectsScheduleList.length;

            for (let i = 0; i < roomScheduleLength; i++) {
              const roomScheduleDetail =
                resRoomSchedule.roomSubjectsScheduleList[i];

              const assignedCourse = classCourseAssignment.find(
                (clsCourse) =>
                  clsCourse.id === roomScheduleDetail.classCourseAssignmentId,
              );

              if (assignedCourse) {
                const resCourse = courseList.find(
                  (course) => course.id === assignedCourse.courseId,
                );

                if (resCourse) {
                  courseName = resCourse.courseName;
                  courseCode = resCourse.courseCode;
                  courseType = resCourse.courseTypeName;
                }
              }

              timeList
                .filter((timeData) => timeData.id === resRoomSchedule.timeId)
                // eslint-disable-next-line no-loop-func
                .map((resTime) => {
                  // Convert time format
                  const startTime = resTime.startTime
                    .replace("01:30", "13:30")
                    .replace("02:30", "14:30")
                    .replace("03:30", "15:30");
                  const endTime = resTime.endTime
                    .replace("02:30", "14:30")
                    .replace("03:30", "15:30");

                  const stime = moment().startOf("day").add(startTime, "hour");
                  const etime = moment().startOf("day").add(endTime, "hour");

                  tempGanttItems.push({
                    id: index,
                    group: resRoomSchedule.dayId,
                    title: courseName,
                    start_time: stime.valueOf(),
                    end_time: etime.valueOf(),
                    courseCode: courseCode,
                    courseType: courseType,
                    classCourseAssignmentId:
                      roomScheduleDetail.classCourseAssignmentId,
                    combinedClassId: roomScheduleDetail.combinedClassId,
                    roomId: result.roomId,
                    staffId: roomScheduleDetail.staffIds,
                    sTime: startTime,
                  });
                  index++;
                });
            }
          });

          if (isExpanded === true) {
            if (tempGanttItems.length === 0) {
              setGanttRoomItems([-1]); // Set ganttItems to an array containing -1
            } else {
              const existingItemIndex = ganttRoomItems.findIndex(
                (item) => item.id === id,
              );

              if (existingItemIndex === -1) {
                // If not exists, add a new item
                setGanttRoomItems((prevGanttRoomItems) => [
                  ...prevGanttRoomItems,
                  {
                    id: id,
                    timeLineItem: tempGanttItems,
                  },
                ]);
              }
            }
          }
        });
    }

    dayList.forEach((day) => {
      setGanttGroup((ganttGroup) => [
        ...ganttGroup,
        {
          id: day.id,
          title: day.dayName,
        },
      ]);
    });
  };

  return (
    <Box className="timetable-content-header">
      <Box sx={{ ml: 3 }}>
        <Grid container>
          <Stack
            direction="row"
            className="fixedSlot-stack timetable-tabs-style"
          >
            <Box className="fixedslot-box-start">
              <Tabs
                value={value}
                onChange={handleChange}
                textColor="secondary"
                indicatorColor="secondary"
              >
                <Tab label="Class" {...a11yProps(0)} />
                <Tab label="Staff" {...a11yProps(1)} />
                <Tab label="Room" {...a11yProps(2)} />
              </Tabs>

              <OutlinedInput
                id="outlined-adornment-weight"
                className="searchTimetable"
                size="small"
                endAdornment={
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                }
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
                placeholder="Search"
                onChange={handleSearchChange}
                value={searchValue}
              />
            </Box>

            <DownloadButton
              selectedDepartment={selectedDepartment}
              handleSelectDepartment={handleSelectDepartment}
              departmentOptions={departmentOptions}
              runalgorithm={runalgorithm}
              anchorRef={anchorRef}
            />
          </Stack>
        </Grid>
      </Box>

      <CustomTabPanel value={value} index={0}>
        <TimetableClassAccordion
          searchDepartmentList={searchDepartmentList}
          expandedMainAccordion={expandedMainAccordion}
          handleAccordion={handleAccordion}
          expandedAccordion={expandedAccordion}
          handleClassAccordion={handleClassAccordion}
          ganttClassItems={ganttClassItems}
          ganttGroup={ganttGroup}
          value={value}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <TimetableStaffAccordion
          searchDepartmentList={searchDepartmentList}
          expandedMainAccordion={expandedMainAccordion}
          handleAccordion={handleAccordion}
          expandedAccordion={expandedAccordion}
          handleClassAccordion={handleClassAccordion}
          ganttStaffItems={ganttStaffItems}
          ganttGroup={ganttGroup}
          value={value}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <TimetableRoomAccordion
          searchBuildingList={searchBuildingList}
          expandedMainAccordion={expandedMainAccordion}
          handleAccordion={handleAccordion}
          expandedAccordion={expandedAccordion}
          handleClassAccordion={handleClassAccordion}
          ganttRoomItems={ganttRoomItems}
          ganttGroup={ganttGroup}
          value={value}
        />
      </CustomTabPanel>
      {handleFailedModel === true && <WarningsClearConfirmation />}
      {clearResultConfirm === true && (
        <ClearResultConfirmation clearResult={clearResult} />
      )}
      {viewOverallCount === true && <ViewCountModel />}
    </Box>
  );
}
