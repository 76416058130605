/* eslint-disable array-callback-return */
import React, { useState } from "react";
import Button from "@mui/material/Button";
import {
  Divider,
  IconButton,
  Stack,
  OutlinedInput,
  Typography,
  Select,
  MenuItem,
  TextField,
  Autocomplete,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector, useDispatch } from "react-redux";
import { AddStaffCourseModel } from "../../../../Store/Reducers/staff_menu";
import StaffCourseAssignmentService from "../../../../Services/StaffCourseAssignmentService";
import { AddStaffCourseAssignmentList } from "../../../../Store/Reducers/staff_menu";
import {
  commonMenuProps,
  textAlignProps,
} from "../../../../Utils/commonMethods";
import "../Staff.css";

// ==============================|| ADD NEW STAFF COURSE ASSIGNMENT MODEL ||============================== //

const AddStaffCourseAssignment = () => {
  const menu = useSelector((state) => state.staff_menu);
  const addStaffCourseModel = menu.addStaffCourseModel;
  const staffList = menu.staffList;
  const staffId = menu.staffId;
  const staffCourseAssignment = menu.staffCourseAssignment;

  const course_menu = useSelector((state) => state.course_menu);
  const courseList = course_menu.courseList;

  const class_menu = useSelector((state) => state.class_menu);
  const classList = class_menu.classList;
  const classCourseAssignment = class_menu.classCourseAssignmentAll;

  const combined_class_menu = useSelector((state) => state.combined_class_menu);
  const classCombinedList = combined_class_menu.classCombinedList;

  const dispatch = useDispatch();

  const newStaffCourse = {
    staffId: 0,
    classCourseId: 0,
    combinedClassId: 0,
    teachingHours: 0,
    continousHourPreference: [],
  };

  let modalOpen = false;
  addStaffCourseModel === true ? (modalOpen = true) : (modalOpen = false);
  const [open, setOpen] = useState(modalOpen);

  const [classId, setClassId] = useState(0);
  const [classCourseId, setClassCourseId] = useState(0);
  const [combinedClassId, setCombinedClassId] = useState(0);
  const [teachingHours, setTeachingHours] = useState(newStaffCourse);
  const [continousHourPreference, setContinousHourPreference] = useState([]);
  const [courseType, setCourseType] = useState("");
  const [handleError, setHandleError] = useState("");
  const [addStaffCourse, setAddStaffCourse] = useState(false);

  const classCourseOptions = [...classCourseAssignment];
  const combinedClassOptions = [...classCombinedList];

  // Function used to close the model
  const handleClose = () => {
    setOpen(false);
    dispatch(AddStaffCourseModel(false));
  };

  const handleSelectClass = (event) => {
    setClassId(event.target.value);
  };

  // Function used to select the class course
  const handleSelectClassCourse = (newvalue) => {
    setContinousHourPreference([]);
    setClassCourseId(newvalue.id);

    classCourseAssignment
      .filter((clsCourse) => clsCourse.id === newvalue.id)
      .map((resClassCourse) => {
        courseList
          .filter((course) => course.id === resClassCourse.courseId)
          .map((res) => {
            setCourseType(res.courseTypeName);
          });
      });
  };

  // Function used to select the combined class course
  const handleSelectCombinedClass = (newValue) => {
    setContinousHourPreference([]);
    setCombinedClassId(newValue.id);

    classCombinedList
      .filter((clsCourse) => clsCourse.id === newValue.id)
      .map((resClassCourse) => {
        courseList
          .filter((course) => course.id === resClassCourse.courseId)
          .map((res) => {
            setCourseType(res.courseTypeName);
          });
      });
  };

  // Function used to select the continuous preference
  const handleSelectContinousHourPreference = (event) => {
    setContinousHourPreference(event.target.value);
  };

  // Function used to handle teaching hours
  const handleTeachingHours = (event) => {
    const { name, value } = event.target;
    setTeachingHours({ [name]: value });
    setContinousHourPreference([]);
  };

  //Function to get the Class Name and Course Name to display in menu box - Class course
  const getAllClassCourseName = (classId, courseId) => {
    const classNames = [];
    const courseNames = [];

    classList
      .filter((cls) => cls.id === classId)
      .map((resCls) => {
        classNames.push(resCls.className);
      });

    courseList
      .filter((course) => course.id === courseId)
      .map((resCourse) => {
        courseNames.push(resCourse.courseName);
      });

    // Combine class names and course names into a single string
    const combinedNames = classNames.map((className, index) => {
      const formattedName = `${className} - ${courseNames[index]}`;
      return formattedName;
    });
    return combinedNames;
  };

  //Function to get the Class Name and Course Name to display in menu box - Combined class course
  const getAllCombinedClassName = (courseId, cid) => {
    const classNames = [];
    const courseName = courseList.find(
      (course) => course.id === courseId,
    )?.courseName; // Get the course name

    // Filter combinedClassOptions by matching courseId
    const matchingClasses = combinedClassOptions.filter(
      (res) => res.id === cid,
    );

    // Iterate through matching classes and create formatted names
    matchingClasses.forEach((matchingClass) => {
      const formattedName = `${matchingClass.combinedClassName} - ${courseName}`;
      classNames.push(formattedName);
    });

    return classNames;
  };

  // Generate possible number pairs
  const generateNumberPairs = (teachingHours) => {
    const numberPairs = [];

    if (
      teachingHours === "1" ||
      teachingHours === "2" ||
      teachingHours === "3"
    ) {
      numberPairs.push([teachingHours]);
    } else if (teachingHours === "4") {
      numberPairs.push([4]);
      numberPairs.push([2, 2]);
    } else if (teachingHours === "5") {
      numberPairs.push([5]);
      numberPairs.push([3, 2]);
    } else if (teachingHours === "6") {
      numberPairs.push([2, 2, 2]);
      numberPairs.push([3, 3]);
    } else if (teachingHours === "12") {
      numberPairs.push([5, 5, 2]);
    }
    return numberPairs;
  };

  const numberPairs = generateNumberPairs(teachingHours.teachingHours);

  // Function used to save the new course
  const handleSave = (event) => {
    setHandleError("");
    let data = {
      staffId: staffId,
      classCourseAssignmentId: classCourseId === 0 ? null : classCourseId,
      combinedClassId: combinedClassId === 0 ? null : combinedClassId,
      teachingHours: teachingHours.teachingHours,
      continuousHourPreference:
        teachingHours.teachingHours !== "1" && courseType !== "Theory"
          ? continousHourPreference.split(",").map(Number)
          : null,
    };
    StaffCourseAssignmentService.createStaffCourseAssignment(data)
      .then((res) => {
        dispatch(
          AddStaffCourseAssignmentList([...staffCourseAssignment, res.data]),
        );
        dispatch(AddStaffCourseModel(false));
      })
      .catch((error) => {
        setHandleError(error.response.data);
      });
  };

  let staffName = "";
  staffList
    .filter((staff) => staff.id === staffId)
    .map((res) => {
      staffName = res.staffName;
    });

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{ sx: { width: "30rem", height: "35.7rem" } }}
      >
        <Stack direction="row">
          <DialogTitle id="responsive-dialog-title">
            Add Staff Course Assignment<br></br> {staffName}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className="close-icon-course-assignment"
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />

        <DialogContent>
          <DialogContentText>
            <center>
              <Typography className="model-text staff-assignment-text1">
                Select Class
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                className="class-dropdown"
                value={classId}
                onChange={handleSelectClass}
                MenuProps={commonMenuProps}
              >
                <MenuItem value={0} disabled>
                  Choose
                </MenuItem>
                <MenuItem value={1}>Class Course </MenuItem>
                <MenuItem value={2}>Combined Class Course</MenuItem>
              </Select>

              <br></br>
              <br></br>
              <br></br>

              {classId === 0 ? (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  className="autocomplete-classcourse"
                  renderInput={(params) => <TextField {...params} label="" />}
                  disabled={classId === 0}
                  ListboxProps={textAlignProps}
                />
              ) : classId === 1 ? (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={classCourseOptions.map((combinedName, index) => ({
                    id: combinedName.id,
                    label: getAllClassCourseName(
                      combinedName.classId,
                      combinedName.courseId,
                    ),
                  }))}
                  className="autocomplete-classcourse"
                  renderInput={(params) => (
                    <TextField {...params} label="Select Class Course" />
                  )}
                  value={classCourseOptions.find(
                    (option) => option.id === classCourseId,
                  )}
                  onChange={(event, newValue) => {
                    newValue !== null &&
                      newValue !== undefined &&
                      handleSelectClassCourse(newValue);
                  }}
                  disabled={classId === 0}
                  ListboxProps={textAlignProps}
                />
              ) : (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={combinedClassOptions.map((combinedName, index) => ({
                    id: combinedName.id,
                    label: getAllCombinedClassName(
                      combinedName.courseId,
                      combinedName.id,
                    ),
                  }))}
                  className="autocomplete-classcourse"
                  renderInput={(params) => (
                    <TextField {...params} label="Select Combined Course" />
                  )}
                  value={combinedClassOptions.find(
                    (option) => option.id === combinedClassId,
                  )}
                  onChange={(event, newValue) => {
                    newValue !== null &&
                      newValue !== undefined &&
                      handleSelectCombinedClass(newValue);
                  }}
                  disabled={classId === 0}
                  ListboxProps={textAlignProps}
                />
              )}

              <Typography className="model-staff-text edit-text1">
                Teaching Hours
              </Typography>
              <OutlinedInput
                id="teachingHours"
                name="teachingHours"
                type="number"
                value={staffCourseAssignment.teachingHours}
                onChange={handleTeachingHours}
                placeholder="Enter Teaching Hours"
                variant="outlined"
                size="small"
                className="input-style"
              />

              <br></br>
              <br></br>

              <Typography className="model-text staff-assignment-text4">
                Select Continous Hour Preference
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="continousHourPreference"
                className="class-dropdown"
                value={continousHourPreference}
                onChange={handleSelectContinousHourPreference}
                disabled={
                  teachingHours.teachingHours <= 1 || courseType === "Theory"
                }
                MenuProps={commonMenuProps}
              >
                <MenuItem value={0} disabled>
                  Choose
                </MenuItem>
                {numberPairs.map((pair, index) => (
                  <MenuItem key={index} value={pair.toString()}>
                    {pair.join(", ")}
                  </MenuItem>
                ))}
              </Select>

              <br></br>
              <br></br>
              <br></br>

              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  className={
                    (classCourseId !== 0 && combinedClassId !== 0) ||
                    Number(teachingHours.teachingHours) <= 0 ||
                    teachingHours.teachingHours === "" ||
                    (courseType === "Lab" &&
                      Number(teachingHours.teachingHours) > 1 &&
                      continousHourPreference.length === 0) ||
                    (courseType === "Theory" &&
                      continousHourPreference.length !== 0) ||
                    addStaffCourse === true
                      ? "save-building-button-disabled"
                      : "save-building-button"
                  }
                  disabled={
                    (classCourseId !== 0 && combinedClassId !== 0) ||
                    Number(teachingHours.teachingHours) <= 0 ||
                    teachingHours.teachingHours === "" ||
                    (courseType === "Lab" &&
                      Number(teachingHours.teachingHours) > 1 &&
                      continousHourPreference.length === 0) ||
                    (courseType === "Theory" &&
                      continousHourPreference.length !== 0) ||
                    addStaffCourse === true
                  }
                  onClick={() => {
                    handleSave();
                    setAddStaffCourse(true);
                  }}
                >
                  Save
                </Button>

                <Button
                  variant="outlined"
                  className="cancel-building-button"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Stack>
              {handleError && (
                <>
                  <p className="duplicate-text handle-error">{handleError}</p>
                  {handleError !== "" &&
                    addStaffCourse &&
                    setAddStaffCourse(false)}
                </>
              )}
            </center>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddStaffCourseAssignment;
