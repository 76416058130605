import React, { useState } from "react";
import Button from "@mui/material/Button";
import {
  Divider,
  IconButton,
  Stack,
  OutlinedInput,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector, useDispatch } from "react-redux";
import { AddClassModel } from "../../../../Store/Reducers/class_menu";
import ClassService from "../../../../Services/ClassService";
import { AddClassList } from "../../../../Store/Reducers/class_menu";
import { commonMenuProps } from "../../../../Utils/commonMethods";
import "./ClassModel.css";

// ==============================|| ADD NEW Class MODEL ||============================== //

const ClassModel = () => {
  const menu = useSelector((state) => state.class_menu);
  const addClassModel = menu.addClassModel;
  const classList = menu.classList;

  const infrastructure_menu = useSelector((state) => state.infrastructure_menu);
  const departmentList = infrastructure_menu.departmentList;

  const dispatch = useDispatch();

  // Local variable used for static design
  const newClass = {
    className: "",
  };

  let modalOpen = false;
  addClassModel === true ? (modalOpen = true) : (modalOpen = false);
  const [open, setOpen] = useState(modalOpen);

  const [classes, setClasses] = useState(newClass);
  const [selectedDepartment, setSelectedDepartment] = useState(0);
  const [handleAddClassError, sethandleAddClassError] = useState("");
  const [addClass, setAddClass] = useState(false);

  const departmentOptions = [...departmentList];

  // Function used to close the model
  const handleClose = () => {
    setOpen(false);
    dispatch(AddClassModel(false));
  };

  // Function used to handle class name
  const handleClassName = (event) => {
    const { name, value } = event.target;
    setClasses({ [name]: value });
  };

  // Function used to select the department
  const handleSelectDepartment = (event) => {
    setSelectedDepartment(event.target.value);
  };

  // Function used to save the new class
  const handleSave = (event) => {
    sethandleAddClassError("");
    let data = {
      className: classes.className.trim(),
      departmentId: selectedDepartment,
    };
    ClassService.createClass(data)
      .then((res) => {
        dispatch(AddClassList([...classList, res.data]));
        dispatch(AddClassModel(false));
      })
      .catch((error) => {
        sethandleAddClassError(error.response.data);
      });
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{ sx: { width: "23rem", height: "23rem" } }}
      >
        <Stack direction="row">
          <DialogTitle id="responsive-dialog-title">Add Class</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className="close-icon-class"
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />

        <DialogContent>
          <DialogContentText>
            <center>
              <br></br>

              <Typography className="model-text class-text1">
                Class Name
              </Typography>
              <OutlinedInput
                id="className"
                name="className"
                type="text"
                value={classList.className}
                onChange={handleClassName}
                placeholder="Enter Class Name"
                variant="outlined"
                size="small"
                className="input-style"
              />
              <br></br>
              <br></br>

              <Typography className="model-text class-text2">
                Choose Department
              </Typography>
              <FormControl sx={{ m: 1 }}>
                <Select
                  value={selectedDepartment}
                  onChange={handleSelectDepartment}
                  size="small"
                  // displayEmpty
                  className="department-dropdown"
                  MenuProps={commonMenuProps}
                >
                  <MenuItem value={0} disabled>
                    Choose
                  </MenuItem>
                  {departmentOptions.map((option, id) => (
                    <MenuItem key={id} value={option.id}>
                      {option.departmentName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <br></br>
              <br></br>

              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  className={
                    classes.className === "" ||
                    selectedDepartment === 0 ||
                    addClass === true
                      ? "save-building-button-disabled"
                      : "save-building-button"
                  }
                  disabled={
                    classes.className === "" ||
                    selectedDepartment === "" ||
                    addClass === true
                  }
                  onClick={() => {
                    handleSave();
                    setAddClass(true);
                  }}
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  className="cancel-class-button"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Stack>
              {handleAddClassError && (
                <>
                  <p className="duplicate-text">{handleAddClassError}</p>
                  {handleAddClassError !== "" && addClass && setAddClass(false)}
                </>
              )}
            </center>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ClassModel;
