import React, { useState } from "react";
import Button from "@mui/material/Button";
import {
  Divider,
  IconButton,
  Stack,
  OutlinedInput,
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector, useDispatch } from "react-redux";
import {
  EditRoomsModel,
  AddNewRoom,
} from "../../../../../Store/Reducers/infrastructure_menu";
import RoomService from "../../../../../Services/RoomService";
import "./RoomModel.css";

// ==============================|| EDIT ROOM MODEL ||============================== //

const EditRoom = () => {
  const menu = useSelector((state) => state.infrastructure_menu);
  const editRoomsModel = menu.editRoomsModel;
  const buildingId = menu.buildingId;
  const addNewRoom = menu.addNewRoom;
  const roomId = menu.roomId;
  const roomInfo = menu.roomInfo;

  const dispatch = useDispatch();

  let modalOpen = false;
  editRoomsModel === true ? (modalOpen = true) : (modalOpen = false);
  const [open, setOpen] = useState(modalOpen);

  const [roomDetailsName, setRoomDetailsName] = useState(roomInfo);
  const [roomDetailsCapacity, setRoomDetailsCapacity] = useState(roomInfo);
  const [roomDetailsMultipleCourse, setRoomDetailsMultipleCourse] =
    useState(roomInfo);
  const [editRoomsName, setEditRoomsName] = useState(roomDetailsName.roomName);
  const [editRoomsCapacity, setEditRoomsCapacity] = useState(
    roomDetailsName.roomCapacity,
  );
  const [editMultipleCourse, setEditMultipleCourse] = useState(
    roomDetailsName.multipleCourseAllowedAtOnetime,
  );
  const [handleEditRoomError, setHandleEditRoomError] = useState("");
  const [editRoom, setEditRoom] = useState(false);

  // Function used to close the model
  const handleClose = () => {
    setOpen(false);
    dispatch(EditRoomsModel(false));
  };

  // Function used to handle room name
  const handleEditRoomName = (event) => {
    setEditRoomsName(event.target.value);
    setRoomDetailsName(event.target.value);
  };

  // Function used to handle room capacity
  const handleEditRoomCapacity = (event) => {
    setEditRoomsCapacity(event.target.value);
    setRoomDetailsCapacity(event.target.value);
  };

  // Function used to handle multiple course
  const handleEditMultipleCourse = (event) => {
    setEditMultipleCourse(event.target.value);
    setRoomDetailsMultipleCourse(event.target.value);
  };

  // Function used to update the Room List
  const changeRoom = (editRoomsName, editRoomsCapacity, editMultipleCourse) => {
    const updatedRoomData = addNewRoom.map((obj) => {
      if (obj.id === roomId) {
        return {
          ...obj,
          roomName:
            editRoomsName === undefined
              ? roomInfo.roomName
              : editRoomsName.trim(),
          roomCapacity:
            editRoomsCapacity === undefined
              ? roomInfo.editRoomsCapacity
              : editRoomsCapacity,

          multipleCourseAllowedAtOnetime:
            editMultipleCourse === undefined
              ? roomInfo.editMultipleCourse
              : editMultipleCourse,
        };
      }
      return obj;
    });
    dispatch(AddNewRoom(updatedRoomData));
  };

  // Function used to edit the room
  const handleEdit = (event) => {
    setHandleEditRoomError("");
    let data = {
      buildingId: buildingId,
      roomName:
        editRoomsName === undefined ? roomInfo.roomName : editRoomsName.trim(),
      roomCapacity:
        editRoomsCapacity === undefined
          ? roomInfo.editRoomsCapacity
          : editRoomsCapacity,

      multipleCourseAllowedAtOnetime:
        editMultipleCourse === undefined
          ? roomInfo.editMultipleCourse
          : editMultipleCourse,
    };
    RoomService.editRoom(roomId, data)
      .then((res) => {
        changeRoom(editRoomsName, editRoomsCapacity, editMultipleCourse);
        handleClose();
      })
      .catch((error) => {
        setHandleEditRoomError(error.response.data);
      });
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          sx: { width: "26rem", height: "26.5rem", boxShadow: "none" },
        }}
      >
        <Stack direction="row">
          <DialogTitle id="responsive-dialog-title">Edit Room</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className="close-icon-room"
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />

        <DialogContent>
          <DialogContentText>
            <center>
              <br></br>
              <Typography className="model-text room-text1">
                Room Name
              </Typography>
              <OutlinedInput
                id="roomName"
                name="roomName"
                type="text"
                value={roomDetailsName.roomName || editRoomsName}
                onChange={handleEditRoomName}
                placeholder="Enter Room Name"
                variant="outlined"
                size="small"
                className="input-style"
              />
              <br></br>

              <Typography className="model-text room-text2">
                Room Capacity
              </Typography>
              <OutlinedInput
                id="roomCapacity"
                name="roomCapacity"
                type="number"
                value={roomDetailsCapacity.roomCapacity || editRoomsCapacity}
                onChange={handleEditRoomCapacity}
                placeholder="Enter Room Capacity"
                variant="outlined"
                size="small"
                className="input-style"
              />

              <Typography className="model-text room-text3">
                Multiple course allowed at one time
              </Typography>
              <FormControl
                className="room-radio-btn"
                onChange={(e) => handleEditMultipleCourse(e)}
              >
                <RadioGroup
                  row
                  aria-labelledby="multipleCourseAllowedAtOnetime"
                  value={
                    roomDetailsMultipleCourse.multipleCourseAllowedAtOnetime ||
                    editMultipleCourse
                  }
                >
                  <FormControlLabel
                    name="multipleCourseAllowedAtOnetime"
                    value={true}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    name="multipleCourseAllowedAtOnetime"
                    value={false}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>

              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  className={
                    editRoomsName === "" ||
                    editRoomsCapacity === 0 ||
                    editRoomsCapacity === "" ||
                    editRoom === true
                      ? "save-building-button-disabled"
                      : "save-building-button"
                  }
                  disabled={
                    editRoomsName === "" ||
                    editRoomsCapacity === 0 ||
                    editRoomsCapacity === "" ||
                    editRoom === true
                  }
                  onClick={() => {
                    handleEdit();
                    setEditRoom(true);
                  }}
                >
                  Edit
                </Button>
                <Button
                  variant="outlined"
                  className="cancel-building-button"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Stack>
              {handleEditRoomError && (
                <>
                  <p className="duplicate-text">{handleEditRoomError}</p>
                  {handleEditRoomError !== "" && editRoom && setEditRoom(false)}
                </>
              )}
            </center>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EditRoom;
