import auth from "../Utils/auth";
import { Buffer } from "buffer";
import axios from "axios";
import API_URL from "../Utils/http-common";
import { getBranchName } from "../Utils/commonMethods";

const createApiService = () => {
  return axios.create({
    baseURL: API_URL,
  });
};

const apiService = createApiService();
const encodedCredentials = Buffer.from(
  auth.username + ":" + auth.password,
).toString("base64");
// ========================================|| GANTTCHART SERVICE ||======================================== //

const getByClassId = (id) => {
  return apiService.get(`/fixedSlotSchedule/class/${id}`, {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const getByStaffId = (id) => {
  return apiService.get(`/fixedSlotSchedule/staff/${id}`, {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const getByRoomId = (id) => {
  return apiService.get(`/fixedSlotSchedule/room/${id}`, {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const getByClassTimetable = (id) => {
  return apiService.get(`/resultClass/classId/${id}`, {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const getByStaffTimetable = (id) => {
  return apiService.get(`/resultStaff/staffId/${id}`, {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const getByRoomTimetable = (id) => {
  return apiService.get(`/resultRoom/roomId/${id}`, {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const getResultClassAll = () => {
  return apiService.get("/resultClass/getAll", {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const getResultStaffAll = () => {
  return apiService.get("/resultStaff/getAll", {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const getResultRoomAll = () => {
  return apiService.get("/resultRoom/getAll", {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const getRoomAnalysis = () => {
  return apiService.get("/roomAnalysis", {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const getOverallCount = () => {
  return apiService.get("/overView", {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

const GanttChartService = {
  getByClassId,
  getByStaffId,
  getByRoomId,
  getByClassTimetable,
  getByStaffTimetable,
  getByRoomTimetable,
  getResultClassAll,
  getResultStaffAll,
  getResultRoomAll,
  getRoomAnalysis,
  getOverallCount,
};

export default GanttChartService;
