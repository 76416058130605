import React, { useState } from "react";
import Button from "@mui/material/Button";
import {
  Divider,
  IconButton,
  Stack,
  OutlinedInput,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Chip,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector, useDispatch } from "react-redux";
import { AddStaffModel } from "../../../../Store/Reducers/staff_menu";
import DoneIcon from "@mui/icons-material/Done";
import { commonMenuProps } from "../../../../Utils/commonMethods";
import "./StaffModel.css";

// ==============================|| ADD NEW STAFF MODEL ||============================== //

const StaffModel = ({
  staffCode,
  staffName,
  selectedDepartment,
  selectedTimePreference,
  departmentOptions,
  handleStaffCode,
  handleStaffName,
  handleSelectDepartment,
  handleSelectTimePreference,
  handleSave,
  selectedTimeId,
  handleAddStaffError,
  handleIsDummy,
  isDummyStaff,
}) => {
  const menu = useSelector((state) => state.staff_menu);
  const addStaffModel = menu.addStaffModel;
  const staffList = menu.staffList;
  const timePreference = menu.timePreference;

  let modalOpen = false;
  addStaffModel === true ? (modalOpen = true) : (modalOpen = false);
  const [open, setOpen] = useState(modalOpen);
  const [addStaff, setAddStaff] = useState(false);

  const dispatch = useDispatch();

  const ITEM_HEIGHT = 48;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5,
        width: 250,
      },
    },
  };

  // Function used to close the model
  const handleClose = () => {
    setOpen(false);
    dispatch(AddStaffModel(false));
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{ sx: { width: "27rem", height: "37rem" } }}
      >
        <Stack direction="row">
          <DialogTitle id="responsive-dialog-title">Add Staff</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className="close-icon-staff"
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />

        <DialogContent>
          <DialogContentText>
            <center>
              <Typography className="model-staff-text add-text1">
                Staff Reference
              </Typography>
              <OutlinedInput
                id="staffCode"
                name="staffCode"
                type="text"
                value={staffList.staffCode}
                onChange={handleStaffCode}
                placeholder="Enter Staff Reference"
                variant="outlined"
                size="small"
                className="input-style"
              />

              <br></br>
              <Typography className="model-staff-text add-text2">
                Staff Name
              </Typography>
              <OutlinedInput
                id="staffName"
                name="staffName"
                type="text"
                value={staffList.staffName}
                onChange={handleStaffName}
                placeholder="Enter Staff Name"
                variant="outlined"
                size="small"
                className="input-style"
              />

              <br></br>
              <Typography className="model-staff-text add-text3">
                Choose Department
              </Typography>
              <FormControl sx={{ m: 1 }}>
                <Select
                  value={selectedDepartment}
                  onChange={handleSelectDepartment}
                  size="small"
                  displayEmpty
                  className="department-dropdown"
                  MenuProps={commonMenuProps}
                >
                  <MenuItem value={0} disabled>
                    {" "}
                    Choose{" "}
                  </MenuItem>
                  {departmentOptions.map((option, id) => (
                    <MenuItem key={id} value={option.id}>
                      {option.departmentName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <br></br>
              <Typography className="model-staff-text add-text4">
                Choose Time Preference
              </Typography>
              <FormControl sx={{ m: 1, width: 300 }}>
                <Select
                  labelId="demo-multiple-chip-label"
                  id="timePreferenceIds"
                  className="staff-timePreference"
                  multiple
                  value={selectedTimePreference}
                  onChange={handleSelectTimePreference}
                  input={<OutlinedInput id="select-multiple-chip" />}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {timePreference.map((time) => (
                    <MenuItem
                      key={time.startTime + time.endTime}
                      value={`${time.startTime} - ${time.endTime}`}
                    >
                      {time.startTime} - {time.endTime}
                      {selectedTimePreference.includes(
                        `${time.startTime} - ${time.endTime}`,
                      ) && <DoneIcon className="staff-doneIcon" />}
                    </MenuItem>
                  ))}
                </Select>
                <Typography
                  sx={{ mt: 2 }}
                  className="model-staff-text add-text5"
                >
                  Dummy Staff or not
                </Typography>
                <FormControl
                  className="isDummy-radio-group-style isDummy-margin"
                  onChange={(e) => handleIsDummy(e)}
                >
                  <RadioGroup
                    row
                    name="isDummyStaffOrNot"
                    aria-labelledby="isDummyStaffOrNot"
                    value={isDummyStaff}
                    sx={{ mt: 1 }}
                  >
                    <FormControlLabel
                      name="isDummyStaffOrNot"
                      value={true}
                      control={<Radio />}
                      label="True"
                    />
                    <FormControlLabel
                      name="isDummyStaffOrNot"
                      value={false}
                      control={<Radio />}
                      label="False"
                    />
                  </RadioGroup>
                </FormControl>
              </FormControl>
              <br></br>
              <br></br>

              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  className={
                    staffCode.staffCode === "" ||
                    staffName.staffName === "" ||
                    selectedDepartment === 0 ||
                    addStaff === true
                      ? "save-building-button-disabled"
                      : "save-building-button"
                  }
                  disabled={
                    staffCode.staffCode === "" ||
                    staffName.staffName === "" ||
                    selectedDepartment === "" ||
                    addStaff === true
                  }
                  onClick={() => {
                    handleSave();
                    setAddStaff(true);
                  }}
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  className="cancel-class-button"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Stack>
              {handleAddStaffError && (
                <>
                  <p className="duplicate-text">{handleAddStaffError}</p>
                  {handleAddStaffError !== "" && addStaff && setAddStaff(false)}
                </>
              )}
            </center>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default StaffModel;
