import React, { useState } from "react";
import Button from "@mui/material/Button";
import {
  Divider,
  IconButton,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { DeleteBuildingModel } from "../../../../../Store/Reducers/infrastructure_menu";

// ==============================|| DELETE BUILDING CONFIRMATION MODEL ||============================== //

const DeleteBuildingConfirmation = ({ handleRemoveBuilding }) => {
  const menu = useSelector((state) => state.infrastructure_menu);
  const deleteBuildingModel = menu.deleteBuildingModel;
  const buildingList = menu.buildingList;
  const buildingId = menu.buildingId;

  let modalOpen = false;
  deleteBuildingModel === true ? (modalOpen = true) : (modalOpen = false);
  const [open, setOpen] = useState(modalOpen);
  const dispatch = useDispatch();

  const [deleteBuilding, setDeleteBuilding] = useState(false);

  // Function used to close the model
  const handleClose = () => {
    setOpen(false);
    dispatch(DeleteBuildingModel(false));
  };

  // Function used to delete a particular building from the list
  const handleDelete = () => {
    buildingList.map(
      (obj) => buildingId === obj.id && handleRemoveBuilding(obj.id),
    );
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          sx: { width: "20rem", height: "12rem", boxShadow: "none" },
        }}
      >
        <Stack direction="row">
          <DialogTitle id="responsive-dialog-title">
            {" Clear Confirmation"}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className="model-close-icon"
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />
        <DialogContent>
          <DialogContentText className="dialog-style" align="center">
            Are you sure you want to Delete ?
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action">
          <Button
            disabled={deleteBuilding === true}
            className={deleteBuilding === true ? "yes-btn-disabled" : "yes-btn"}
            onClick={() => {
              handleDelete();
              setDeleteBuilding(true);
            }}
            variant="outlined"
          >
            Yes
          </Button>
          <Button className="no-btn" onClick={handleClose} variant="outlined">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteBuildingConfirmation;
