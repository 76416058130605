import React from "react";
import {
  Alert,
  Stack,
  AlertTitle,
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import AlgorithmWarnings from "./AlgorithmWarnings";
import { useSelector } from "react-redux";
import "../Timetable.css";

// ==============================|| WARNINGS ||============================== //

const Warnings = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const menu = useSelector((state) => state.menu);
  const warningsList = menu.warningsList;

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Function for Download Error List
  const downloaderrorlist = () => {
    const element = document.createElement("a");
    let b = "";
    warningsList.forEach((val, i) => {
      b = b + `${i + 1}. ${val}\n`;
    });
    const file = new Blob([b], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = "WarningList.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  return (
    <>
      <div>
        {warningsList.length > 0 && (
          <Alert severity="error" className="import-warning">
            <Stack direction="row">
              <AlertTitle className="import-warning__alert-title">
                <b>Info : {warningsList.length} Warnings</b>
              </AlertTitle>
              <Stack direction="row" className="view_icon">
                <IconButton
                  sx={{ borderRadius: "5%" }}
                  onClick={() => {
                    handleOpen();
                  }}
                >
                  <VisibilityIcon
                    className="visibilityIcon-color"
                    sx={{ paddingRight: "5px" }}
                  />
                  <Typography className="view_title">View</Typography>
                </IconButton>
              </Stack>
              <Stack direction="row" className="download_icon">
                <IconButton
                  sx={{ borderRadius: "5%" }}
                  onClick={() => {
                    downloaderrorlist();
                  }}
                >
                  <VerticalAlignBottomIcon
                    className="visibilityIcon-color"
                    sx={{ paddingRight: "5px" }}
                  />
                  <Typography className="download_title">Download</Typography>
                </IconButton>
              </Stack>
            </Stack>
          </Alert>
        )}
        {warningsList.length === 0 && <AlgorithmWarnings />}
      </div>
      <div>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          aria-labelledby="responsive-dialog-title"
          PaperProps={{
            sx: {
              maxWidth: "38rem",
              width: "100%",
              height: "100%",
              minHeight: "40rem",
            },
          }}
        >
          <Stack direction="row">
            <DialogTitle id="responsive-dialog-title">
              <b>{"Warning List"}</b>
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "#64748B",
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Divider />
          <DialogContent>
            <DialogContentText className="errorDialog-style">
              {warningsList.map((val, i) => (
                <p>{` ${i + 1} . ${val}`}</p>
              ))}
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default Warnings;
