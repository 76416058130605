import React, { useEffect, useState } from "react";
import {
  Grid,
  Stack,
  IconButton,
  Tooltip,
  Typography,
  Paper,
  InputAdornment,
  OutlinedInput,
  Snackbar,
  Alert,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import { AddCourseModel } from "../../../Store/Reducers/course_menu";
import CourseModel from "./Course Models/CourseModel";
import CourseList from "./CourseList";
import SearchIcon from "@mui/icons-material/Search";
import CourseService from "../../../Services/CourseService";
import Loader from "../../Loader/Loader";
import AlgorithmWarnings from "../Timetable/Warnings/AlgorithmWarnings";
import { AddCourseList } from "../../../Store/Reducers/course_menu";
import "./Courses.css";
import SemesterSelect from "../../SemesterSelectWarning/SemesterSelect";

// ==============================|| COURSE MAIN PAGE ||============================== //

const Courses = ({ handleCourseClick, handleRemoveCourse }) => {
  const dispatch = useDispatch();

  const menu = useSelector((state) => state.course_menu);
  const addCourseModel = menu.addCourseModel;
  const courseList = menu.courseList;
  const isCourseStatusCode = menu.isCourseStatusCode;

  const infrastructure_menu = useSelector((state) => state.infrastructure_menu);
  const isBuildingStatusCode = infrastructure_menu.isBuildingStatusCode;
  const isDepartmentStatusCode = infrastructure_menu.isDepartmentStatusCode;

  const class_menu = useSelector((state) => state.class_menu);
  const isClassStatusCode = class_menu.isClassStatusCode;

  const combined_class_menu = useSelector((state) => state.combined_class_menu);
  const isCombinedClassStatusCode =
    combined_class_menu.isCombinedClassStatusCode;

  const staff_menu = useSelector((state) => state.staff_menu);
  const isStaffStatusCode = staff_menu.isStaffStatusCode;

  const fixedslot_menu = useSelector((state) => state.fixedslot_menu);
  const isFixedSlotStatusCode = fixedslot_menu.isFixedSlotStatusCode;

  const common_menu = useSelector((state) => state.menu);
  const algorithmStatus = common_menu.algorithmStatus;

  // Local variable used for course
  const newCourse = {
    courseCode: "",
    courseName: "",
    courseHours: 0,
    courseTypeName: "",
  };

  const [searchCourseList, setSearchCourseList] = useState([]);
  const [courseCode, setCourseCode] = useState(newCourse);
  const [courseName, setCourseName] = useState(newCourse);
  const [courseHours, setCourseHours] = useState(newCourse);
  const [courseTypeName, setCourseTypeName] = useState("Theory");
  const [courseDuplicate, setCourseDuplicate] = useState("");
  const [addCourseSuccessful, setAddCourseSuccessful] = useState(false);
  const [addCourseError, setAddCourseError] = useState(false);
  const [handleAddCourseError, setHandleAddCourseError] = useState();

  useEffect(() => {
    // Check if courseList is defined before using it
    if (courseList && courseList.length > 0) {
      setSearchCourseList(courseList);
    }
  }, [courseList]);

  // Function used to retrieve search text
  const handleSearchChange = (e) => {
    e.preventDefault();
    searchCourse(e.target.value);
  };

  // Function used for search course
  const searchCourse = (text) => {
    const filteredCourses = courseList.filter((value) => {
      const searchText = text.toLowerCase();

      const lowercaseCourseName = value.courseName.toLowerCase();
      const lowercaseCourseCode = value.courseCode.toLowerCase().toString();

      const uppercaseCourseName = value.courseName.toUpperCase();
      const uppercaseCourseCode = value.courseCode.toUpperCase().toString();

      return (
        lowercaseCourseName.includes(searchText) ||
        lowercaseCourseCode.includes(searchText) ||
        uppercaseCourseName.includes(searchText) ||
        uppercaseCourseCode.includes(searchText)
      );
    });

    setSearchCourseList(filteredCourses);
  };

  // Function used to handle the course code
  const handleCourseCode = (event) => {
    const { name, value } = event.target;
    setCourseCode({ [name]: value });
  };

  // Function used to handle the course name
  const handleCourseName = (event) => {
    const { name, value } = event.target;
    setCourseName({ [name]: value });
  };

  // Function used to handle the course hours
  const handleCourseHours = (event) => {
    const { name, value } = event.target;
    setCourseHours({ [name]: value });
  };

  // Function used to handle the course type
  const handleCourseTypeName = (event) => {
    setCourseTypeName(event.target.value);
  };

  // Function used to save the new course
  const handleSave = (event) => {
    setHandleAddCourseError("");
    let data = {
      courseCode: courseCode.courseCode.trim(),
      courseName: courseName.courseName.trim(),
      courseHours: courseHours.courseHours,
      courseTypeName: courseTypeName,
    };

    if (
      courseList.some(
        (value) =>
          value.courseCode === data.courseCode ||
          value.courseCode.toLowerCase() === data.courseCode,
      )
    ) {
      setCourseDuplicate("Course code already exists!");
    } else {
      CourseService.createCourse(data)
        .then((res) => {
          dispatch(AddCourseList([...courseList, res.data]));
          dispatch(AddCourseModel(false));

          if (res.status === 200) {
            setAddCourseSuccessful(true);
          } else {
            setAddCourseError(true);
          }
        })
        .catch((error) => {
          setHandleAddCourseError(error.response.data);
        });
    }
  };

  // Function for closing the snackbar
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAddCourseSuccessful(false);
    setAddCourseError(false);
  };

  return (
    <>
      {(isBuildingStatusCode === false ||
        isDepartmentStatusCode === false ||
        isCourseStatusCode === false ||
        isClassStatusCode === false ||
        isCombinedClassStatusCode === false ||
        isStaffStatusCode === false ||
        isFixedSlotStatusCode === false ||
        algorithmStatus === "") &&
      sessionStorage.getItem("branchName") !== null ? (
        <Loader />
      ) : (
        <>
          {sessionStorage.getItem("branchName") !== null ? (
            <>
              <div className="display-warnings">
                <AlgorithmWarnings />
              </div>

              <Paper className="courses-tab-paper-padding" sx={{ mt: 0 }}>
                <Grid container className="course-content-body">
                  <Grid item xs={12}>
                    <Stack direction="row">
                      <OutlinedInput
                        id="outlined-adornment-weight"
                        className="course-search-box"
                        size="small"
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                        placeholder="Search"
                        onChange={handleSearchChange}
                      />

                      <Tooltip title="Add Course" placement="right" arrow>
                        <IconButton
                          className={
                            algorithmStatus === null ||
                            algorithmStatus === "FAILED" ||
                            algorithmStatus === "UNKNOWN" ||
                            algorithmStatus === "NOT_CREATED"
                              ? "add-course-button"
                              : "add-course-button-disabled"
                          }
                          type="submit"
                          variant="contained"
                          onClick={() => {
                            dispatch(AddCourseModel(true));
                          }}
                          disabled={
                            algorithmStatus !== null &&
                            algorithmStatus !== "FAILED" &&
                            algorithmStatus !== "UNKNOWN" &&
                            algorithmStatus !== "NOT_CREATED"
                          }
                        >
                          <AddIcon className="add-icon" />
                        </IconButton>
                      </Tooltip>

                      <Typography className="course-text">
                        <b>Courses</b>
                      </Typography>

                      <Typography className="course-text total-course-style">
                        <b>Number of Courses : </b>
                      </Typography>

                      <Typography className="total-course-count ">
                        {courseList.length}
                      </Typography>
                    </Stack>
                  </Grid>

                  <Grid
                    container
                    className="courses-list-style courses-list-margin"
                  >
                    <CourseList
                      courseList={searchCourseList}
                      handleCourseClick={handleCourseClick}
                      handleRemoveCourse={handleRemoveCourse}
                    />
                  </Grid>

                  {addCourseModel === true && (
                    <CourseModel
                      courseCode={courseCode}
                      courseName={courseName}
                      courseHours={courseHours}
                      courseTypeName={courseTypeName}
                      courseDuplicate={courseDuplicate}
                      setCourseDuplicate={setCourseDuplicate}
                      handleSave={handleSave}
                      handleCourseCode={handleCourseCode}
                      handleCourseName={handleCourseName}
                      handleCourseHours={handleCourseHours}
                      handleCourseTypeName={handleCourseTypeName}
                      handleAddCourseError={handleAddCourseError}
                    />
                  )}
                </Grid>
              </Paper>

              <Snackbar
                open={addCourseSuccessful}
                autoHideDuration={5000}
                onClose={handleClose}
              >
                <Alert
                  onClose={handleClose}
                  severity="success"
                  className="alert-success-style"
                >
                  New Course successfully added!
                </Alert>
              </Snackbar>

              <Snackbar
                open={addCourseError}
                autoHideDuration={5000}
                onClose={handleClose}
              >
                <Alert
                  onClose={handleClose}
                  severity="error"
                  className="alert-error-style"
                >
                  Error in adding new course!
                </Alert>
              </Snackbar>
            </>
          ) : (
            <SemesterSelect />
          )}
        </>
      )}
    </>
  );
};

export default Courses;
