import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { AddModalElement } from "../../../../Store/Reducers/menu";
import { ShortcutModal } from "../../ShortcutModal/ShortcutModal";
import { uiTexts } from "../../../../Utils/uiTexts";

// ==============================|| CLASS GANTTCHART MODEL ||============================== //

export const GanttChartModel = ({ item, ganttGroup, value }) => {
  const {
    labelText: {
      staffIdText,
      roomIdText,
      classIdText,
      courseCodeText,
      courseNameText,
      combinedClassNameText,
      classNameLabelText,
      numberOfStudentsText,
      roomNameLabelText,
      staffNameLabelText,
    },
  } = uiTexts;

  const dispatch = useDispatch();

  const infrastructure_menu = useSelector((state) => state.infrastructure_menu);
  const addNewRoom = infrastructure_menu.addNewRoom;
  const class_menu = useSelector((state) => state.class_menu);
  const classList = class_menu.classList;
  const classCourseAssignment = class_menu.classCourseAssignmentAll;
  const combined_class_menu = useSelector((state) => state.combined_class_menu);
  const classCombinedList = combined_class_menu.classCombinedList;
  const staff_menu = useSelector((state) => state.staff_menu);
  const staffList = staff_menu.staffList;

  //Getting all details to be displayed in Ganttchart model
  let className = "",
    numberOfStudents = "",
    roomNames = [],
    staffNames = [],
    classId: "",
    classNames = [];

  if (item.classCourseAssignmentId !== null) {
    const matchingCourse = classCourseAssignment.find(
      (clsCourse) => clsCourse.id === item.classCourseAssignmentId,
    );

    if (matchingCourse) {
      numberOfStudents = matchingCourse.numberOfStudents;
      className = classList.find(
        (cls) => cls.id === matchingCourse.classId,
      )?.className;

      classId = classList?.find((cls) => cls.id === matchingCourse.classId)?.id;
    }

    if (Array.isArray(item.roomId)) {
      item.roomId.forEach((roomId) => {
        const matchingRooms = addNewRoom.filter((room) => room.id === roomId);

        matchingRooms?.forEach((room) => {
          roomNames.push({ id: room.id, name: room.roomName });
        });
      });
    } else {
      const matchingRooms = addNewRoom.filter(
        (room) => room.id === item.roomId,
      );

      matchingRooms?.forEach((room) => {
        roomNames.push({ id: room.id, name: room.roomName });
      });
    }

    if (Array.isArray(item.staffId)) {
      item.staffId.forEach((staffId) => {
        const matchingStaffs = staffList.filter(
          (staff) => staff.id === staffId,
        );

        matchingStaffs?.forEach((resStaff) => {
          staffNames.push({ id: resStaff.id, name: resStaff.staffName });
        });
      });
    } else {
      const matchingStaffs = staffList.filter(
        (staff) => staff.id === item.staffId,
      );

      matchingStaffs?.forEach((resStaff) => {
        staffNames.push({ id: resStaff.id, name: resStaff.staffName });
      });
    }
  }

  if (item.combinedClassId !== null) {
    const matchingCombinedCourse = classCombinedList.find(
      (clsComCourse) => clsComCourse.id === item.combinedClassId,
    );

    if (matchingCombinedCourse) {
      numberOfStudents = matchingCombinedCourse.numberOfStudents;
      className = matchingCombinedCourse.combinedClassName;

      const matchingClassIds = classCourseAssignment
        ?.filter((assignment) =>
          item.combinedClassCourseIds?.includes(assignment.id),
        )
        .map((assignment) => assignment.classId);

      classList
        ?.filter((classes) => matchingClassIds?.includes(classes.id))
        .forEach((names) => {
          classNames.push({ id: names.id, name: names.className });
        });
    }

    if (Array.isArray(item.roomId)) {
      item.roomId.forEach((roomId) => {
        const matchingRooms = addNewRoom.filter((room) => room.id === roomId);

        matchingRooms?.forEach((room) => {
          roomNames.push({ id: room.id, name: room.roomName });
        });
      });
    } else {
      const matchingRooms = addNewRoom.filter(
        (room) => room.id === item.roomId,
      );

      matchingRooms?.forEach((room) => {
        roomNames.push({ id: room.id, name: room.roomName });
      });
    }

    if (Array.isArray(item.staffId)) {
      item.staffId.forEach((staffId) => {
        const matchingStaffs = staffList.filter(
          (staff) => staff.id === staffId,
        );

        matchingStaffs?.forEach((resStaff) => {
          staffNames.push({ id: resStaff.id, name: resStaff.staffName });
        });
      });
    } else {
      const matchingStaffs = staffList.filter(
        (staff) => staff.id === item.staffId,
      );

      matchingStaffs?.forEach((resStaff) => {
        staffNames.push({ id: resStaff.id, name: resStaff.staffName });
      });
    }
  }

  const handleShortcutModalClick = (idLabel, idValue) => {
    dispatch(AddModalElement({ [idLabel]: idValue }));
  };

  return (
    <>
      <div class="grid-container">
        <div class="grid-label" className="hoursText-color">
          {courseCodeText}
        </div>

        <div class="grid-item">{item.courseCode}</div>

        <div class="grid-label" className="hoursText-color">
          {courseNameText}
        </div>

        <div class="grid-item">{item.title}</div>

        <div class="grid-label" className="hoursText-color">
          {item.classCourseAssignmentId !== null
            ? classNameLabelText
            : combinedClassNameText}
        </div>

        <div class="grid-item">
          {value === 0 || item.combinedClassId !== null ? (
            className
          ) : (
            <span
              onClick={() => handleShortcutModalClick(classIdText, classId)}
              className="shortcut-modal-text-link"
            >
              {className}
            </span>
          )}
        </div>

        {item.combinedClassCourseIds?.length > 0 && (
          <>
            <div class="grid-label" className="hoursText-color">
              {classNameLabelText}
            </div>

            <div class="grid-item">
              <React.Fragment>
                {classNames?.map((classes, index) => (
                  <div key={index}>
                    {value === 0 ? (
                      <span key={classes.id}>{classes.name}</span>
                    ) : (
                      <span
                        key={classes.id}
                        onClick={() =>
                          handleShortcutModalClick(classIdText, classes.id)
                        }
                        className="shortcut-modal-text-link"
                      >
                        {classes.name}
                      </span>
                    )}
                  </div>
                ))}
              </React.Fragment>
            </div>
          </>
        )}

        <div class="grid-label" className="hoursText-color">
          {numberOfStudentsText}
        </div>

        <div class="grid-item">{numberOfStudents}</div>

        <div class="grid-label" className="hoursText-color">
          {roomNameLabelText}
        </div>

        <div class="grid-item">
          <React.Fragment>
            {roomNames?.map((room, index) => (
              <div key={index}>
                {value === 2 ? (
                  <span key={room.id}>{room.name}</span>
                ) : (
                  <span
                    key={room.id}
                    onClick={() =>
                      handleShortcutModalClick(roomIdText, room.id)
                    }
                    className="shortcut-modal-text-link"
                  >
                    {room.name}
                  </span>
                )}
              </div>
            ))}
          </React.Fragment>
        </div>

        <div class="grid-label" className="hoursText-color">
          {staffNameLabelText}
        </div>

        <div class="grid-item">
          <React.Fragment>
            {staffNames?.map((staff, index) => (
              <div key={index}>
                {value === 1 ? (
                  <span key={staff.id}>{staff.name}</span>
                ) : (
                  <span
                    key={staff.id}
                    onClick={() =>
                      handleShortcutModalClick(staffIdText, staff.id)
                    }
                    className="shortcut-modal-text-link"
                  >
                    {staff.name}
                  </span>
                )}
              </div>
            ))}
          </React.Fragment>
        </div>
      </div>

      <ShortcutModal ganttGroup={ganttGroup} />
    </>
  );
};
