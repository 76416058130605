import React, { useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Radio,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useSelector, useDispatch } from "react-redux";
import LoginService from "../../../Services/LoginService";
import { SemesterDetails } from "../../../Store/Reducers/login_menu";
import AddSemesterModal from "./AddSemesterModal";
import Loader from "../../Loader/Loader";
import "./HomePage.css";

// ==============================|| HOME PAGE ||============================== //

export default function HomePage({
  handleSemesterChange,
  selectedSemester,
  setSelectedSemester,
}) {
  const headCells = [
    "",
    "S.No",
    "Academic year",
    "Semester type",
    "Created date",
    "Updated date",
    "Completed date",
    "Status",
  ];

  const login_menu = useSelector((state) => state.login_menu);
  const semesterDetails = login_menu.semesterDetails;
  const selectedSemesterId = login_menu.selectedSemesterId;
  const semesterLoader = login_menu.semesterLoader;

  const dispatch = useDispatch();

  const [importDataFromSemesterId, setImportDataFromSemesterId] = useState(0);
  const [academicYear, setAcademicYear] = useState(0);
  const [semesterType, setSemesterType] = useState(0);
  const [addSemesterError, setAddSemesterError] = useState("");
  const [addSemesterModal, setAddSemesterModal] = useState(false);
  const [addNewClick, setAddNewClick] = useState(false);

  //Function to handle academic year
  const handleAcademicYear = (event) => {
    setAddSemesterError("");
    setAddNewClick(false);
    setAcademicYear(event.target.value);
  };

  //Function to handle Semestername
  const handleSemesterType = (event) => {
    setAddSemesterError("");
    setAddNewClick(false);
    setSemesterType(event.target.value);
  };

  //Function to handle Semester
  const handleSemester = (event) => {
    setAddSemesterError("");
    setAddNewClick(false);
    setImportDataFromSemesterId(event.target.value);
  };

  //Function to handle save new semester
  const handleSave = () => {
    let data = {
      importDataFromSemesterId: importDataFromSemesterId,
      academicYear: academicYear,
      semesterType: semesterType,
    };
    LoginService.addSemester(data)
      .then((res) => {
        dispatch(SemesterDetails([...semesterDetails, res.data]));
        handleClose();
      })
      .catch((error) => {
        setAddSemesterError(error.response.data);
      });
  };

  //Function to close modal
  const handleClose = () => {
    setAddSemesterModal(false);
    setAcademicYear(0);
    setSemesterType(0);
    setAddSemesterError("");
    setImportDataFromSemesterId(0);
    setSelectedSemester(selectedSemesterId);
  };

  //Function to format date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  };

  return (
    <>
      {semesterLoader === false ? (
        <Loader />
      ) : (
        <div className="semester--table semester-table-box">
          <Button
            className="add-new-semester-button"
            onClick={() => setAddSemesterModal(true)}
          >
            <AddIcon sx={{ mr: 1 }} />
            New Semester
          </Button>

          <TableContainer className="semester--table-container semester-table-height">
            <Table stickyHeader aria-label="simple table">
              <TableRow className="semester-table-header-sticky">
                {headCells.map((ob, i) => (
                  <TableCell key={i} className="semester--table-head-title">
                    {ob}
                  </TableCell>
                ))}
              </TableRow>

              <TableBody>
                {semesterDetails.map((row, index) => {
                  const isSelected = row.id === selectedSemesterId;
                  return (
                    <TableRow
                      tabIndex={-1}
                      key={row.id}
                      selected={isSelected}
                      onClick={() => handleSemesterChange(row.id)}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell
                        padding="radio"
                        className="semester-content-text table-cell-body"
                      >
                        <Radio
                          checked={isSelected}
                          color="primary"
                          sx={{
                            "& .MuiSvgIcon-root": {
                              width: 18,
                              height: 18,
                            },
                          }}
                        />
                      </TableCell>

                      <TableCell className="semester-content-text table-cell-body">
                        {index + 1}
                      </TableCell>

                      <TableCell className="semester-content-text table-cell-body">
                        {row.academicYear}
                      </TableCell>

                      <TableCell className="semester-content-text table-cell-body">
                        {row.semesterType}
                      </TableCell>

                      <TableCell className="semester-content-text table-cell-body">
                        {formatDate(row.planCreatedDate)}
                      </TableCell>

                      <TableCell className="semester-content-text table-cell-body">
                        {row.planUpdatedDate
                          ? formatDate(row.planUpdatedDate)
                          : "-"}
                      </TableCell>

                      <TableCell className="semester-content-text table-cell-body">
                        {row.planCompletedDate
                          ? formatDate(row.planCompletedDate)
                          : "-"}
                      </TableCell>

                      <TableCell
                        style={{ borderRight: "none" }}
                        className="semester-content-text table-cell-body"
                      >
                        {row.planStatus}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}

      {addSemesterModal === true && (
        <AddSemesterModal
          academicYear={academicYear}
          semesterType={semesterType}
          importDataFromSemesterId={importDataFromSemesterId}
          addSemesterModal={addSemesterModal}
          setAddSemesterModal={setAddSemesterModal}
          handleAcademicYear={handleAcademicYear}
          handleSemesterType={handleSemesterType}
          handleSave={handleSave}
          handleClose={handleClose}
          addSemesterError={addSemesterError}
          handleSemester={handleSemester}
          addNewClick={addNewClick}
          setAddNewClick={setAddNewClick}
        />
      )}
    </>
  );
}
