import React from "react";
import GanttChart from "../FixedSlots/GanttCharts/GanttChart";
import "../ShortcutModal/shortcutmodal.css";

// -------------------------------- Shortcut Modal Timeline View ---------------------------------- //

export const ShortcutModalTimelineView = ({
  statusCode,
  ganttItems,
  ganttGroup,
  isShortcutModalOpen,
}) => {
  return (
    <>
      {statusCode &&
        ganttItems.map((result) => (
          <GanttChart
            key={result.id}
            ganttGroup={ganttGroup}
            resultItems={result.timeLineItem}
            isShortcutModalOpen={isShortcutModalOpen}
          />
        ))}
    </>
  );
};
