import React from "react";
import { Grid, Typography } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import CampusPlannerLogo from "../../Assets/Images/CampusPlannerLogo.png";
import { removeUserSession } from "../../Utils/commonMethods";
import "./Header.css";

// ==============================|| HEADER ||============================== //

const Header = ({
  handleSemesterChange,
  selectedSemester,
  setSelectedSemester,
}) => {
  const navigate = useNavigate();

  // Function used to handle Logout
  const handleLogout = () => {
    removeUserSession();
    navigate("/");
  };

  return (
    <>
      <Grid className="header">
        <Grid
          className="header-layout"
          justifyContent="space-between"
          component="main"
        >
          <Grid>
            <img
              src={CampusPlannerLogo}
              className="campusplanner-logo"
              alt="logo"
            />
          </Grid>
          <Grid>
            <Typography className="semesterName-text">
              <span>{sessionStorage.getItem("semesterType")}</span>
              <span>{sessionStorage.getItem("academicYear")}</span>
            </Typography>
          </Grid>
        </Grid>
        <Tooltip title="Logout" placement="bottom" arrow>
          <IconButton
            className="logout-iconButton"
            type="submit"
            variant="contained"
          >
            <LogoutIcon onClick={handleLogout} className="logout-icon" />
          </IconButton>
        </Tooltip>
      </Grid>
    </>
  );
};

export default Header;
