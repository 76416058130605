// This file will be used for storing all the text and labels.

export const uiTexts = Object.freeze({
  labelText: {
    staffText: "Staff",
    departmentText: "Department",
    staffIdText: "staffId",
    dayTimeText: "DAY/TIME",
    courseText: "Course",
    classText: "Class",
    roomText: "Room",
    roomIdText: "roomId",
    buildingText: "Building",
    combinedClassText: "Combined Class",
    classIdText: "classId",
    classNameText: "className",
    roomNameText: "roomName",
    idText: "id",
    staffNameText: "staffName",
    courseCodeText: "Course code",
    courseNameText: "Course name",
    combinedClassNameText: "Combined class name",
    classNameLabelText: "Class name",
    numberOfStudentsText: "Number of students",
    roomNameLabelText: "Room name",
    staffNameLabelText: "Staff name",
  },

  buttonText: {
    timelineText: "Timeline",
    tableText: "Table",
  },

  symbolText: {
    colonText: ": ",
  },
});
