import React from "react";
import { IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { RemoveModalElement } from "../../../Store/Reducers/menu";
import "../ShortcutModal/shortcutmodal.css";

// -------------------------------- Shortcut Modal Close Button ------------------------------- //

export const ShortcutModalCloseButton = () => {
  const dispatch = useDispatch();

  return (
    <IconButton onClick={() => dispatch(RemoveModalElement())}>
      <CloseIcon className="shortcut-modal-close-icon" />
    </IconButton>
  );
};
