import React from "react";
import "../ShortcutModal/shortcutmodal.css";
import { Grid, Typography, Divider } from "@mui/material";
import { useSelector } from "react-redux";
import { uiTexts } from "../../../Utils/uiTexts";

// ------------------------------- Shortcut Modal Table View -------------------------------- //

export const ShortcutModalTableView = ({ ganttItems, modal }) => {
  const {
    labelText: {
      dayTimeText,
      courseText,
      classText,
      roomText,
      staffText,
      combinedClassText,
      classNameText,
      roomNameText,
      idText,
      staffNameText,
    },
    symbolText: { colonText },
  } = uiTexts;

  const infrastructure_menu = useSelector((state) => state.infrastructure_menu);
  const addNewRoom = infrastructure_menu.addNewRoom;
  const menu = useSelector((state) => state.class_menu);
  const classList = menu.classList;
  const staff_menu = useSelector((state) => state.staff_menu);
  const staffList = staff_menu.staffList;

  const rows = 7;
  const cols = 6;
  const tableRows = [];

  // Function to get class label
  const getClassLabelText = (classId) => {
    return classId === Number(classId) ? classText : combinedClassText;
  };

  // Function to get class name
  const getClassName = (classId) => {
    return classId === Number(classId)
      ? getNameById(classList, classId, idText, classNameText)
      : classId;
  };

  // Function to get name by ID
  const getNameById = (list, id, keyValue, nameValue) => {
    const item = list?.find((item) => item[keyValue] === id);
    return item ? item[nameValue] : "";
  };

  // Function to get names based on IDs
  const getNamesByIds = (list, ids, nameKey) => {
    const matchingItems = list?.filter((item) => ids?.includes(item.id));
    return matchingItems?.map((item) => item[nameKey]).join(", ");
  };

  // Helper function to format time
  const formatTime = (hour) => {
    let start, end;

    if (hour === 12) {
      start = "13:30";
      end = "14:30";
    } else if (hour === 13) {
      start = "14:30";
      end = "15:30";
    } else {
      start = `${hour}:30`;
      end = `${hour + 1}:30`;
    }

    return `${start} - ${end}`;
  };

  const generateTableCellContent = (
    index,
    label1,
    value1,
    label2,
    value2,
    label3,
    value3,
  ) => {
    return (
      <>
        <Grid container>
          <Grid item>
            <Typography className="label-viewModel">
              {label1} {colonText}
            </Typography>
          </Grid>

          <Grid item>
            <Typography className="value-viewModel">{value1}</Typography>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item>
            <Typography className="label-viewModel">
              {label2} {colonText}
            </Typography>
          </Grid>

          <Grid item>
            <Typography className="value-viewModel">{value2}</Typography>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item>
            <Typography className="label-viewModel">
              {label3} {colonText}
            </Typography>
          </Grid>

          <Grid item>
            <Typography className="value-viewModel">{value3}</Typography>
          </Grid>
        </Grid>
      </>
    );
  };

  // Generate table rows
  for (let i = 0; i < rows; i++) {
    const cells = [];

    for (let j = 0; j < cols; j++) {
      if (i === 0 && j === 0) {
        cells.push(
          <th
            key={j}
            className="view-header shortcut-modal-table-sticky-header"
          >
            {dayTimeText}
          </th>,
        );
      } else if (i === 0) {
        cells.push(
          <th
            key={j}
            className="view-header shortcut-modal-table-sticky-header"
          >
            {formatTime(8 + j)}
          </th>,
        );
      } else {
        if (j === 0) {
          cells.push(<th key={j} className="view-header">{`DAY ${i}`}</th>);
        } else {
          cells.push(
            <td key={j} className="view-content cellStyles">
              {(() => {
                const tableCellContent = ganttItems[0]?.timeLineItem
                  ?.map((data) => {
                    if (data.group === i && data.timeId === j) {
                      const roomName = data.roomId;
                      const className = getClassName(data.classId);
                      const courseName = data.title;
                      const staffName = data.staffId;
                      const classLabel = getClassLabelText(data.classId);

                      return {
                        roomName,
                        className,
                        courseName,
                        staffName,
                        classLabel,
                      };
                    }

                    return null;
                  })
                  .filter(Boolean); // Filter out any null values to prevent rendering issues

                return tableCellContent?.map((data, index) => {
                  let content;

                  if (modal === staffText) {
                    content = generateTableCellContent(
                      index,
                      courseText,
                      data.courseName,
                      data.classLabel,
                      data.className,
                      roomText,
                      getNameById(
                        addNewRoom,
                        data.roomName,
                        idText,
                        roomNameText,
                      ),
                    );
                  } else if (modal === roomText) {
                    content = generateTableCellContent(
                      index,
                      courseText,
                      data.courseName,
                      data.classLabel,
                      data.className,
                      staffText,
                      getNamesByIds(staffList, data.staffName, staffNameText),
                    );
                  } else if (modal === classText) {
                    content = generateTableCellContent(
                      index,
                      courseText,
                      data.courseName,
                      staffText,
                      getNamesByIds(staffList, data.staffName, staffNameText),
                      roomText,
                      getNamesByIds(addNewRoom, data.roomName, roomNameText),
                    );
                  }

                  return (
                    <div key={index}>
                      {content}
                      {index < tableCellContent.length - 1 && (
                        <Divider className="shortcut-modal-table-cell-divider" />
                      )}
                    </div>
                  );
                });
              })()}
            </td>,
          );
        }
      }
    }

    // Add a row to the tableRows array
    tableRows.push(<tr key={i}>{cells}</tr>);
  }

  const tableBody = tableRows?.map((row, index) => (
    <tr
      key={index}
      className={index % 2 === 0 ? "even-tableRow" : "odd-tableRow"}
    >
      {row.props.children}
    </tr>
  ));

  return (
    <div className="shortcut-modal-scroll">
      <center>
        <table className="shortcut-modal-table-body">
          <tbody>{tableBody}</tbody>
        </table>
      </center>
    </div>
  );
};
